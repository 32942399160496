const backend = [
  {
    id: 'b4257ff56b3244b9bd70c87803f8b3d2',
    name: 'Les bases de Node.js',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    pro: true,
    level: 2
  },
  {
    id: '4f04b6c86d3343469e98e98499110138',
    name: 'Créer une app dans le terminal',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    pro: true,
    level: 2
  },
  {
    id: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    name: 'NPM',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    pro: true,
    level: 2
  },
  {
    id: '6b4c4b1cdc93413ea1112d15abff030c',
    name: 'Node et Express',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    pro: true,
    level: 2
  },
  {
    id: '02f8dbd02b734cf1b2e3571d5cc9b119',
    name: 'MongoDB et Mongoose',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    pro: true,
    level: 3
  },
  {
    id: '39feec0033e74f40bf6fb1eb545a69ce',
    name: 'Certification',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    pro: true,
    level: 4
  }
]

export default backend
