// Icons
import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiNodedotjs,
  SiJest,
  SiReact,
  SiCypress,
  SiNpm,
  SiExpress,
  SiMongodb,
  SiFirebase,
  SiRedux,
  SiStyledcomponents,
  SiReactrouter,
  SiStripe,
  SiNextdotjs
} from 'react-icons/si'

const certifications = [
  {
    id: 'd264d4f791ca4303b3b43a871cfe2436',
    name: 'Responsive Web Design',
    code: 'ResponsiveWebDesign',
    meta: {
      subtitle: 'Pour créer de magnifiques interfaces',
      icons: [
        <SiHtml5 key='html5' style={{ color: '#D84924' }} />,
        <SiCss3 key='css3' style={{ color: '#1B81BD' }} />
      ]
    }
  },
  {
    id: '309f6c2d3b764fc98831da3ba96615ae',
    name: 'JavaScript',
    code: 'Javascript',
    meta: {
      subtitle: 'Ton premier langage de développement',
      icons: [<SiJavascript key='javascript' style={{ color: '#EAD41C' }} />]
    }
  },
  {
    id: '93921cc46acb48dcb0ad3d5e68164d91',
    name: 'Développement Front-End',
    code: 'FrontEnd',
    meta: {
      subtitle: 'Des sites modernes et puissants',
      icons: [
        <SiJavascript key='javascript' style={{ color: '#EAD41C' }} />,
        <SiReact key='SiReact' style={{ color: '#62DBFB' }} />,
        <SiRedux key='SiRedux' style={{ color: 'rebeccapurple' }} />,
        <SiStyledcomponents
          key='SiStyledcomponents'
          style={{ color: '#F2C22E' }}
        />,
        <SiReactrouter key='SiReactrouter' style={{ color: '#EC4545' }} />
      ]
    }
  },
  {
    id: 'f15c2c6cc5454771840a65469de84b6b',
    name: 'Développement Back-End',
    code: 'BackEnd',
    meta: {
      subtitle: 'Pour utiliser JavaScript côté serveur',
      icons: [
        <SiNpm key='SiNpm' style={{ color: '#C20000' }} />,
        <SiNodedotjs key='SiNodedotjs' style={{ color: '#00FF00' }} />,
        <SiExpress key='SiExpress' style={{ color: '#FFF' }} />,
        <SiMongodb key='SiMongodb' style={{ color: '#00FF00' }} />
      ]
    }
  },
  {
    id: '16aeafbbf7464cbe83aa16986ce94b21',
    name: 'Indie Hackers',
    code: 'Serverless',
    meta: {
      subtitle: 'La nouvelle façon de créer de sites web',
      icons: [
        <SiNextdotjs key='SiNextdotjs' style={{ color: '#fff' }} />,
        <SiFirebase key='SiFirebase' style={{ color: '#F2C22E' }} />,
        <SiStripe key='SiStripe' style={{ color: '#625AFA' }} />
      ]
    }
  },
  {
    id: 'dc7e32d9e66b48f6bac69f5de08741b4',
    name: 'Tester JavaScript',
    code: 'Testing',
    meta: {
      subtitle: 'Apprendre les bonnes pratiques',
      icons: [
        <SiJest key='SiJest' style={{ color: '#CC4614' }} />,
        <SiCypress key='SiCypress' style={{ color: '#FFF' }} />
      ]
    }
  }
]

export default certifications
