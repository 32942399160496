import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import firebaseConfig from './config'

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig)
    this.auth = app.auth()
    this.db = app.firestore()
    this.analytics = app.analytics()

    if (window.location.hostname === 'localhost') {
      this.db.useEmulator('localhost', 8080)
      this.auth.useEmulator('http://localhost:9099')
    }

    this.googleProvider = new app.auth.GoogleAuthProvider()
    this.githubProvider = new app.auth.GithubAuthProvider()
  }

  // Auth API
  login = async provider => {
    // await this.auth.signInWithRedirect(this[`${provider}Provider`])
    const user = await this.auth.signInWithPopup(this[`${provider}Provider`])

    const { isNewUser } = user.additionalUserInfo

    return isNewUser
  }

  logout = async () => {
    await this.auth.signOut()
  }

  // DB Access shortcut
  lessons = uid => this.db.collection(`users/${uid}/lessons`)

  // A mettre dans le cloud
  // setCurrentLesson = (uid, currentLesson) =>
  //   this.db.collection(`users`).doc(uid).set({ currentLesson }, { merge: true })

  saveLesson = (uid, certificationId, lessonId) =>
    this.db
      .collection(`users/${uid}/lessons`)
      .doc(certificationId)
      .set(
        {
          [lessonId]: Date.now()
        },
        { merge: true }
      )
}

const firebase = new Firebase()

export default firebase
