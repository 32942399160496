// Contexts
import { FirebaseProvider } from './contexts/FirebaseContext'
import { LessonsProvider } from './contexts/LessonsContext'
import { NextProvider } from './contexts/NextContext'
import { LoadingProvider } from './contexts/LoadingContext'
import { RewardfulProvider } from './contexts/RewardfulContext'
import { stripePromise } from './stripe'
import { Elements } from '@stripe/react-stripe-js'
// Styles
import { GlobalStyle } from './theme/GlobalStyle'

// Routes
import Routes from './routes/Routes'

// Helpers
import ScrollToTop from './components/ScrollToTop'

const App = () => {
  return (
    <>
      <LoadingProvider>
        <RewardfulProvider>
          <LessonsProvider>
            <FirebaseProvider>
              <NextProvider>
                <Elements stripe={stripePromise}>
                  <GlobalStyle />
                  <ScrollToTop />
                  <Routes />
                </Elements>
              </NextProvider>
            </FirebaseProvider>
          </LessonsProvider>
        </RewardfulProvider>
      </LoadingProvider>
    </>
  )
}

export default App
