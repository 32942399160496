import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

import useLessonsContext from '../hooks/useLessonsContext'

import Loading from '../components/Loading'
// import Lesson from '../pages/Lesson'
import RequireAuth from './RequireAuth'
// Layout
const Layout = lazy(() => import('../components/Layout'))
// import Layout from '../components/Layout'

// Private Route

// Pages
const Home = lazy(() => import('../pages/Home'))
// const Tarifs = lazy(() => import('../pages/Tarifs'))
// const Financement = lazy(() => import('../pages/Financement'))
const Programme = lazy(() => import('../pages/Programme'))
const NotFound = lazy(() => import('../pages/NotFound'))
const Cgv = lazy(() => import('../pages/Cgv'))
const Confidentialite = lazy(() => import('../pages/Confidentialite'))
const Login = lazy(() => import('../pages/Login'))
const Lesson = lazy(() => import('../pages/Lesson'))
const Profile = lazy(() => import('../pages/Profile'))
const Checkout = lazy(() => import('../pages/Checkout'))
const CheckoutOld = lazy(() => import('../pages/CheckoutOld'))
const Success = lazy(() => import('../pages/Success'))
const Failed = lazy(() => import('../pages/Failed'))
const Welcome = lazy(() => import('../pages/Welcome'))
const Jdc = lazy(() => import('../pages/Jdc'))
const Leads = lazy(() => import('../pages/Leads'))
const GiveAway = lazy(() => import('../pages/GiveAway'))
const GiveAwayAccess = lazy(() => import('../pages/GiveAwayAccess'))
const ThanksLead = lazy(() => import('../pages/ThanksLead'))
const JdcYoutube = lazy(() => import('../pages/JdcYoutube'))
const Disappointed = lazy(() => import('../pages/Disappointed'))
const Free = lazy(() => import('../pages/Free'))
const Video = lazy(() => import('../pages/Video'))

const AllRoutes = () => {
  const { lessons } = useLessonsContext()

  if (!lessons) return <Loading text={`Chargement...`} />

  return (
    <Suspense fallback={<Loading text={`Chargement...`} />}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='login' element={<Login />} />
          <Route path='tarifs' element={<Login />} />
          {/* <Route path='financement' element={<Financement />} /> */}
          <Route path='programme' element={<Programme />} />
          <Route path='cgv' element={<Cgv />} />
          <Route path='tombola' element={<GiveAwayAccess />} />
          <Route path='jdc' element={<Jdc />} />
          <Route path='jdcyt' element={<JdcYoutube />} />
          <Route path='s/*' element={<Leads />} />
          <Route path='thanks' element={<ThanksLead />} />
          <Route path='free' element={<Free />} />
          <Route
            path='failed'
            element={
              <RequireAuth>
                <Failed />
              </RequireAuth>
            }
          />
          <Route
            path='checkout/tombola'
            element={
              <RequireAuth>
                <GiveAway />
              </RequireAuth>
            }
          />
          <Route
            path='welcome'
            element={
              <RequireAuth>
                <Welcome />
              </RequireAuth>
            }
          />
          <Route
            path='success'
            element={
              <RequireAuth>
                <Success />
              </RequireAuth>
            }
          />
          <Route
            path='profil'
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path='checkout/:pageId'
            element={
              <RequireAuth>
                <Checkout />
              </RequireAuth>
            }
          />
          <Route
            path='checkout'
            element={
              <RequireAuth>
                <Checkout />
              </RequireAuth>
            }
          />
          <Route
            path='checkoutold'
            element={
              <RequireAuth>
                <CheckoutOld />
              </RequireAuth>
            }
          />
          <Route
            path='avis'
            element={
              <RequireAuth>
                <Disappointed />
              </RequireAuth>
            }
          />
          <Route path='video/*' element={<Video />} />
          {lessons.map(lesson => (
            <Route
              key={lesson.id}
              path={`app/${lesson.slug}`}
              element={
                <RequireAuth>
                  <Lesson lesson={lesson} />
                </RequireAuth>
              }
            />
          ))}
          <Route
            path='politique-de-confidentialite'
            element={<Confidentialite />}
          />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default AllRoutes
