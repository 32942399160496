import { useState } from 'react'

const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false)

  return {
    isLoading,
    setIsLoading
  }
}

export default useLoading
