import { createContext } from 'react'

import useLoading from '../hooks/useLoading'

const LoadingContext = createContext(null)

export const LoadingProvider = ({ children }) => {
  const { ...props } = useLoading()

  return (
    <LoadingContext.Provider value={{ ...props }}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingContext
