const javascript = [
  {
    id: 'ed3b2eba9b8c40bc94732f42144e3e95',
    name: 'Les bases de JavaScript',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: false,
    level: 1
  },
  {
    id: '1c8a36a1ca5040a3b073527e151fa134',
    name: 'ES6',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 2
  },
  {
    id: '5742fa2a9af84b77b7a494f267a3e549',
    name: 'Les expressions régulières',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 2
  },
  {
    id: '1c4d8c870dd04d0489f379c5987109a8',
    name: 'Déboguer',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 2
  },
  {
    id: '8e522b366d3241be8ecef62d6e37e42f',
    name: 'Les bases de la structure de données',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: false,
    level: 2
  },
  {
    id: '2dcbad20bbbc4d12a1c027633bff2ec6',
    name: "Exercices d'algorithme basiques",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: false,
    level: 2
  },
  {
    id: '50376c3cd57043e7b2f5a96e986e2ea2',
    name: 'Programmation Orientée Objet',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 3
  },
  {
    id: '96c3417cc35a424ea5d3bbf1e0510c2d',
    name: 'Programmation Fonctionnelle',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 3
  },
  {
    id: '88a949f8289f4a63a38295e3a9608a1d',
    name: "Exercices d'algorithme intermédiaires",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 3
  },
  {
    id: '166d7c456a18468dbc19459fc1e8c505',
    name: 'Certification',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    pro: true,
    level: 4
  }
]

export default javascript
