import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Router
import { BrowserRouter } from 'react-router-dom'

// Reset CSS
import 'sanitize.css'

// Fonts
import '@fontsource/josefin-sans'
import '@fontsource/irish-grover'
// import '@fontsource/work-sans'
import '@fontsource/fira-mono'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
