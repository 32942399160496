// Data
import certifications from '../data/certifications'
import backend from '../data/backend'
import frontend from '../data/frontend'
import jamstack from '../data/jamstack'
import javascript from '../data/javascript'
import dataLessons from '../data/lessons'
import responsive from '../data/responsive'
import testing from '../data/testing'

import slugify from 'slugify'

const chapters = [].concat(
  responsive,
  javascript,
  frontend,
  jamstack,
  testing,
  backend
)

const lessons = dataLessons.map(lesson => ({
  ...lesson,
  slug: slugify(lesson.name, {
    lower: true,
    strict: true
  })
}))

const useLessons = () => {
  return { certifications, chapters, lessons }
}

export default useLessons
