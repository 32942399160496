const testing = [
  {
    id: '244227d9749c4108a56fc80e565bf66a',
    name: 'Coder un projet React en TDD',
    certification: 'dc7e32d9e66b48f6bac69f5de08741b4',
    pro: true,
    level: 3
  }
]

export default testing
