import useLessonsContext from './useLessonsContext'
import { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import useFirebaseContext from './useFirebaseContext'

const useNext = () => {
  const { user, firebase } = useFirebaseContext()
  const navigate = useNavigate()
  const { lessons } = useLessonsContext()

  const [currentLesson, setCurrentLesson] = useState(null)
  const [nextLesson, setnextLesson] = useState(null)
  const [nextFreeLesson, setnextFreeLesson] = useState(null)
  const [showAds, setShowAds] = useState(false)
  // show est utilisé pour cacher la leçon scrimba au clique pour éviter la
  // modale "ëtes vous sur de vouloir quitter".
  const [show, setShow] = useState(true)

  useEffect(() => {
    // Save current lesson in firebase
    // Faire ça mais depuis le cloud
    // firebase.setCurrentLesson(user.uid, currentLesson)
    setShowAds(false)

    const currentIndex = lessons.findIndex(
      lesson => lesson.id === currentLesson?.id
    )

    // if (currentIndex + 1 === lessons.length) {
    //   setnextLesson('end')
    //   return
    // }

    const nextLesson = lessons[currentIndex + 1] || ''

    const nextFreeLesson =
      lessons
        .slice(currentIndex + 1, lessons.length)
        .filter(lesson => !lesson.premium)[0] || ''

    setnextLesson(nextLesson)
    setnextFreeLesson(nextFreeLesson)
  }, [currentLesson, lessons])

  const handleClick = () => {
    setShow(false)
    if (!Object.keys(user?.lessons).includes(currentLesson.id)) {
      firebase.saveLesson(
        user.uid,
        currentLesson.certification,
        currentLesson.id
      )
    }
    if (nextLesson.id === 'last') return navigate(`/programme`)

    if (nextLesson.id !== nextFreeLesson.id && !user.isPro) {
      return setShowAds(true)
    }

    navigate(`/app/${nextLesson.slug}`)
  }

  return {
    nextLesson,
    nextFreeLesson,
    setCurrentLesson,
    handleClick,
    showAds,
    setShowAds,
    show,
    setShow
  }
}

export default useNext
