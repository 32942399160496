import { createContext } from 'react'

import firebase from '../firebase'
import useAuth from '../hooks/useAuth'

const FirebaseContext = createContext(null)

export const FirebaseProvider = ({ children }) => {
  const user = useAuth()

  return (
    <FirebaseContext.Provider value={{ user, firebase }}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseContext
