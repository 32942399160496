const jamstack = [
  {
    id: 'dba96816900845d4bd1bfc2c700fbb51',
    name: 'Découverte de TypeScript avec Next.js',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    pro: true,
    level: 2
  }
]

export default jamstack
