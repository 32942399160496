import styled, { keyframes } from 'styled-components'
import { colors } from '../theme/colors'

const Div = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${colors.black};
  color: ${colors.white};
`
const rotate = keyframes`
from { transform: rotate(0deg); } to { transform: rotate(360deg); }
`
const Svg = styled.svg`
  max-width: 3rem;
  /* border: 5px solid red; */
  margin: auto;
  text-align: center;
  display: block;
  animation: 5s linear 0s infinite ${rotate};
`

const Loading = ({ text = '' }) => {
  return (
    <Div className='d-flex-center'>
      <div>
        <Svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 6 6'
          shape-rendering='crispEdges'
        >
          <rect fill='#45D62F' x='0' y='0' width='1' height='1' />
          <rect fill='#45D62F' x='1' y='0' width='1' height='1' />
          <rect fill='#45D62F' x='2' y='0' width='1' height='1' />
          <rect fill='#45D62F' x='3' y='0' width='1' height='1' />
          <rect fill='#45D62F' x='4' y='0' width='1' height='1' />
          <rect fill='#45D62F' x='5' y='0' width='1' height='1' />

          <rect fill='#45D62F' x='0' y='1' width='1' height='1' />
          <rect fill='#45D62F' x='1' y='1' width='1' height='1' />
          <rect fill='#45D62F' x='2' y='1' width='1' height='1' />
          <rect fill='#45D62F' x='3' y='1' width='1' height='1' />
          <rect fill='#45D62F' x='4' y='1' width='1' height='1' />
          <rect fill='#45D62F' x='5' y='1' width='1' height='1' />

          <rect fill='#45D62F' x='0' y='2' width='1' height='1' />
          <rect fill='#45D62F' x='1' y='2' width='1' height='1' />
          <rect fill='#45D62F' x='2' y='2' width='1' height='1' />
          <rect fill='#45D62F' x='3' y='2' width='1' height='1' />
          <rect fill='#45D62F' x='4' y='2' width='1' height='1' />
          <rect fill='#45D62F' x='5' y='2' width='1' height='1' />

          <rect fill='#45D62F' x='0' y='3' width='1' height='1' />
          <rect fill='#45D62F' x='1' y='3' width='1' height='1' />
          <rect fill='#45D62F' x='2' y='3' width='1' height='1' />
          <rect fill='#FFFFFF' x='3' y='3' width='1' height='1' />
          <rect fill='#E72A8B' x='4' y='3' width='1' height='1' />
          <rect fill='#FFFFFF' x='5' y='3' width='1' height='1' />

          <rect fill='#45D62F' x='0' y='4' width='1' height='1' />
          <rect fill='#45D62F' x='1' y='4' width='1' height='1' />
          <rect fill='#45D62F' x='2' y='4' width='1' height='1' />
          <rect fill='#E72A8B' x='3' y='4' width='1' height='1' />
          <rect fill='#E72A8B' x='4' y='4' width='1' height='1' />
          <rect fill='#E72A8B' x='5' y='4' width='1' height='1' />

          <rect fill='#45D62F' x='0' y='5' width='1' height='1' />
          <rect fill='#45D62F' x='1' y='5' width='1' height='1' />
          <rect fill='#45D62F' x='2' y='5' width='1' height='1' />
          <rect fill='#E72A8B' x='3' y='5' width='1' height='1' />
          <rect fill='#E72A8B' x='4' y='5' width='1' height='1' />
          <rect fill='#E72A8B' x='5' y='5' width='1' height='1' />
        </Svg>
        <h1 className='monospace'>{text}</h1>
      </div>
    </Div>
  )
}

export default Loading
