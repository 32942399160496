const lessons = [
  {
    id: '77b4d17cfd6f4769ac864dddb7610f5f',
    name: 'Ton premier élément HTML',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coef6491e88fd40fc0cf2f82f'
  },
  {
    id: '2d2327346113417bb1fcaa9b08e6da8e',
    name: "Comprendre les titres avec l'élément h2",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'cob914dedbc4a18299f09cd3c'
  },
  {
    id: 'd18ce8d43f35495cb80ed685a3933304',
    name: 'Informe avec la balise paragraphe',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co95349b1baad504700f9e386'
  },
  {
    id: 'a1acd8baf572437e87d97ad103076015',
    name: 'Remplir la page avec du Lorem Ipsum',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'cod764c019a403f1d9c14de70'
  },
  {
    id: '4f20550c1e21485595b477fa7cddfe7f',
    name: 'Décommenter du HTML',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co6d34c83935b00e17366383c'
  },
  {
    id: '32605a4b9aca4ddf9de1f9652b338d16',
    name: 'Commenter du HTML',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coed149d798223573086a17e8'
  },
  {
    id: 'e0ddd8fd3294421abd2524032ce817f6',
    name: 'Supprimer un élément HTML',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coca44fbdad5362b4264312bb'
  },
  {
    id: 'a9cbb3b9d08d495081324ba7a8a4c971',
    name: 'Introduction aux éléments HTML5',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coacc4531804e09bcb8231749'
  },
  {
    id: 'c795e3c04e214731ae389a37041b4995',
    name: 'Ajouter des images',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co0b649b2b10ccfa6cff268ad'
  },
  {
    id: '8b888882edb1483d9e6d6d3a8586843f',
    name: 'Créer un lien vers une page externe avec une ancre',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coaad4b1d86852abef0d1aba1'
  },
  {
    id: '237eb2b5b88c4a61a9fdd08d4bbfac4f',
    name: 'Lier vers une section interne',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co694430da0cca78c30944159'
  },
  {
    id: '5c9e54022d6244088c8a0dfa55308231',
    name: 'Imbriquer un lien dans un paragraphe',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co3a7486f9f379a7ffa4c6829'
  },
  {
    id: '6c795af337864550beb4f39e5a48a3d5',
    name: 'Créer un lien mort avec le dièse',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co219495db0a4700e02f5cb0c'
  },
  {
    id: '22e9324e5a51402ab567abc0f4a58f50',
    name: 'Transformer une image en lien',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'cod1743c1b4763c4cdb3e5240'
  },
  {
    id: 'e11e1aa6b912485e825d54f512afee2f',
    name: 'Créer une liste à puces désordonnée',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'cofc347a3b8e40662a6ab717c'
  },
  {
    id: '11873676c98e4d79bc72d29b1002bfc2',
    name: 'Créer une liste numérotée',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co7524508b6be9ff90c150b1b'
  },
  {
    id: '29e495f87ba14a11bcb84331d41d01c1',
    name: 'Créer un champ de texte',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co2e94138a26a0a34e6dbf22b'
  },
  {
    id: '8d7fe598935c41a6b864b83841e8f39d',
    name: 'Ajouter un texte indicatif au champ',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co02b49939bf9b363f700a696'
  },
  {
    id: '500fb1cc7f7a4cf48da191c2eab19fc0',
    name: 'Créer un formulaire',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co6914005a7ac67e3bfec7c92'
  },
  {
    id: '1fe6071408694e9d9e950d6288de1265',
    name: 'Ajouter un bouton submit',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co389400685d5973c64fe022c'
  },
  {
    id: '632d2f6ce69a4336b11d2863bbf798ee',
    name: "Utiliser l'attribut required",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coc8d4bc79b532969f179d4d3'
  },
  {
    id: '27ab856d5d684e78b01d60a7be9d8e3c',
    name: 'Créer des boutons radio',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co03249b0ba936d092cebcfd1'
  },
  {
    id: 'ed33bf3344bd46dab9a9e68f801965ce',
    name: 'Créer des boutons checkbox',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co5aa4d5d9c04a7d9a65393d1'
  },
  {
    id: '540138fb09fa46a2aaa733604fdd7c43',
    name: "Utiliser l'attribue value avec les boutons radio et checkbox",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coe2f4dfbb7f1dc0fc0b2c09e'
  },
  {
    id: 'deab8a41619d4a8e88d811d93b72d08d',
    name: 'Sélectionner une option par défaut',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coae64e81a47b7998f034db3f'
  },
  {
    id: 'ecf521c4dc62437da313b618513908f6',
    name: 'Imbriquer plusieurs éléments dans une div',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'coaee4eacbdae1c131b7b9fb1'
  },
  {
    id: '7e18a76549d0409c91c6446a4eebded4',
    name: "Déclarer le Doctype d'un document HTML",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co8f04877b30fa5853b94df0f'
  },
  {
    id: '8a4addb2631840a5bf7b7f5de4e3fc9d',
    name: "Définir le Head et le Body d'un document HTML",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '949ac38b30c24861b807e7ed33e8496e',
    premium: false,
    scrimba: 'co22943b5bba86f4430b32dcf'
  },
  {
    id: '3f170e443e4b4825845612886557523d',
    name: 'Changer la couleur du texte',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co2cd4eb2a7954d191b2cdd0f'
  },
  {
    id: 'bd6644fa60aa4468922528a17ce445db',
    name: 'Utiliser les sélecteurs CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co850436a8c44cbae32f8bbe2'
  },
  {
    id: '3e6b6da11c174a528674cb860f25dfef',
    name: 'Utiliser les classes',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cod7b425eada61da1d4327cad'
  },
  {
    id: 'efc54db89bad406f81228e7dc8c580c1',
    name: 'Mettre en forme plusieurs éléments avec les classes',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coe214dce90e8e2acf30082ed'
  },
  {
    id: '81c0de44cb0142059a2005ec1756021a',
    name: 'Modifier la font-size du texte',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cocbb43f4a40ba4686e7df451'
  },
  {
    id: '5ac02159dc804ce0a28562cb82b3345e',
    name: "Modifier la police d'écriture",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co1d04f6e91f19df678a47ed2'
  },
  {
    id: '949af7b8e08d4cf7bde2f4c0306f6bce',
    name: 'Importer une Google Font',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cocb942218049c8a45b7411bd'
  },
  {
    id: '38177181788c4b0c9ec2c9b1da2819d3',
    name: 'Spécifier une police par défaut',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'codd24f99b6e09a120c2dc83c'
  },
  {
    id: '8825560d68c84894b28d180fc0eabd0f',
    name: 'Dimensionner une image',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co9554109abe6a0c23c6579df'
  },
  {
    id: '089a2d40d4164f009918d3a5a01039f6',
    name: 'Ajouter des bordures aux éléments',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cob5f48ee847955a09f3652ca'
  },
  {
    id: '8f946ccec77145a9a3b3ebfed914ca73',
    name: 'Arrondir les bordures border-radius',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cob1546b5955c44ea5204f361'
  },
  {
    id: 'ab65a7fabdbf4afcb27e97c0b8dc4d06',
    name: 'Rendre une image circulaire avec border-radius',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coa1b4328b0e99f6c5dc69dff'
  },
  {
    id: 'f846f75d20264a498ea86f92c1368e80',
    name: 'Donner une couleur de fond à un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co4694812819c402f04fc4920'
  },
  {
    id: '1d8ff88826df47f4a098a47ec2ed1f70',
    name: "Paramétrer l'id d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coe9a4e5aaa9b800ad6c9b0cd'
  },
  {
    id: 'ecdb795854274a50913e59cb587b54d7',
    name: 'Utiliser un id pour mettre en forme un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co2bf47cfb7d7fb1d918893c8'
  },
  {
    id: 'eae7df6bdb064f5787c0863072211107',
    name: "Ajuster le padding d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co9284eea9de19e7e4bb39cf1'
  },
  {
    id: 'fc03ee3566de43798c61d42e00bf277f',
    name: "Ajuster la margin d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cob234e4385dd7906f5fc19a6'
  },
  {
    id: '04c60ce3bc864ede9f02e5bec969af2e',
    name: 'Utiliser une marge négative',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'codac44e39345b8e343de83f8'
  },
  {
    id: '6345d6109ccf4beaa943aac8cd60717b',
    name: "Ajouter un padding différent à chaque côté d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coc7641d381540b62bb43e1d7'
  },
  {
    id: '241945819f17464bba558576a20ef3c6',
    name: "Ajouter une margin différente à chaque côté d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co50f4315a9112fc79528f5d7'
  },
  {
    id: 'fd45a7449b9a47eeb507d05c8872bbb2',
    name: 'Utiliser la notation raccourcie pour le padding',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cod154b5fac7279afca4ca4b9'
  },
  {
    id: '0cf9c44ce2fb49d7b8f125ea617bec37',
    name: 'Utiliser la notation raccourcie pour la margin',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coc3a47ff9e2984c946a14815'
  },
  {
    id: '8d2d462bb0c94e5cbc0baf27f5d4bbda',
    name: "Utiliser l'attribut pour sélectionner un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co27f48d5b99f03ec9c603b78'
  },
  {
    id: '93f939f52cd64af29edb9d05c9f8ae0d',
    name: 'Comprendre la différence entre la position absolute et relative',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coeac43a2845aee1c3ea83e5c'
  },
  {
    id: 'f914db4dd8fa4eeabd24f1a3f1de1571',
    name: 'Mettre en forme le body',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co4b040dea67242484538be3a'
  },
  {
    id: 'dc2993e632f24749b7974ff88924ca23',
    name: 'Style hériter du body',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co768452395743fc733543c2b'
  },
  {
    id: 'cd5d6ca24c9240b58a088080a6629e00',
    name: 'Rendre un style prioritaire',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cof3a47e2a3be418825e5154e'
  },
  {
    id: '1eaac48d3674467aba9e6bccb9994a00',
    name: 'Écraser un style par un autre',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cod8d4081b3b05c50f4716f57'
  },
  {
    id: '36987a398e7248c09adecfc53c8ed7d2',
    name: "Écraser un déclaration de classe par celle d'un id",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cof9c4b6cb3f3a970caa77850'
  },
  {
    id: '9b825cf8cd7c482fbd06f618281eef63',
    name: 'Écraser un déclaration de classe par un style inline',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coa18423b963106bf7e14b24f'
  },
  {
    id: '3aee5c8e2d4a4e32b21468e53df459fc',
    name: 'Écraser tous les styles avec !important',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cocbe428bb14d03cf2a9acf0a'
  },
  {
    id: '3fa5035e23234107ba7b69904e9a6852',
    name: 'Utiliser les couleurs hexadécimales',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co14147638632b3fb78d0c550'
  },
  {
    id: 'f514a467a4ea4963ace6c57523231fbe',
    name: 'Mélanger des couleurs hexadécimales',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co8514966956fb95aed68ac07'
  },
  {
    id: '6294050bfd11425f92d54123292fd95c',
    name: 'Utilisation la notation raccourcie pour les couleurs hexadécimales',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co2a04d7893bfddfe996f17f9'
  },
  {
    id: 'ed064d87148d4bb38b8adb0141a5a2b4',
    name: 'Utiliser le RGB pour ajouter de la couleur à un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co0c049789bc83ea3052762b5'
  },
  {
    id: '8f077c1d00284e39978629cf55799213',
    name: 'Utiliser RGB pour mélanger des couleurs',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co0e441a7b99f8ffdf6b88a1e'
  },
  {
    id: '99a779fb35eb42dab2ab3c6bdd3c36de',
    name: 'Découverte des variables CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cob62437da713eaf6eeaec6dd'
  },
  {
    id: 'b0a9ccbed331402ba11c9fc1d93cbf3b',
    name: 'Créer une variable CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co56b4ea796b3cafe49bc641b'
  },
  {
    id: 'a273656e81dd4c1eba7c63a197c815fe',
    name: 'Utiliser une variable CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co09845089d825a5891ebcdf8'
  },
  {
    id: 'afce4e534a5a476e93ce8df358f40880',
    name: 'Utiliser une valeur par défaut avec une variable CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co7a54c89a84195352d8a83bf'
  },
  {
    id: 'feb1813a5ac6469dadd748be1eed2dd7',
    name:
      'Améliorer la compatibilité avec les anciens navigateurs pour les variables CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'coeb1426b86f5327130288004'
  },
  {
    id: 'b84cca3f8660422184e0094249cc3a0a',
    name: "Hériter d'une variable CSS",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co0e14885a62ac54ba2987c01'
  },
  {
    id: '525d640a697944c6a9e56328e9f0e15f',
    name: 'Modifier une variable pour un élément spécifique',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'co6464c41bcf739ed78e8f8ed'
  },
  {
    id: '5c6258a81d214a43941ff0e05846904e',
    name: 'Utiliser une media query pour modifier une variable',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '5c80db0e4c744434b15fae6d9b2bfacc',
    premium: false,
    scrimba: 'cob914b90b246e040d1e74473'
  },
  {
    id: '245e4d927c664adf93e66156f7c0de06',
    name: 'Comprendre la propriété text-align',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '48e437df83b0493db7f930c8185986f6',
    name: "Modifier la largeur d'un élément avec width",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '7dc32997d6ea4f3bac40d81003ad6997',
    name: "Modifier la hauteur d'un élément avec height",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '53a1d6f5aa0a4ff4859fda5f0eddad74',
    name: 'Mettre un texte en gras avec la balise strong',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '014f4bd3c90841d1bc04b7a31606c6a2',
    name: 'Souligner du texte avec la balise u',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '3215dc26134241ef9c0440a13a1c435b',
    name: 'Rendre un texte italique avec la balise em',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'fa0a5c17a6ae4171a0695eb1cb05e74e',
    name: 'Rayer du texte avec la balise s',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '2e8ac37d606b4b909ee8ba963f722808',
    name: 'Créer une barre horizontale avec la balise hr',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '3776a0aa4a434957ad8d631953e1c193',
    name: 'Modifier la couleur de fond du texte',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '636364f4fae1401cb7c50f284fccc9fd',
    name: "Modifier la taille d'un header VS du texte",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '53b5eb56ce194531982b5900d1e5a72a',
    name: 'Ajouter des ombres grâce à box-shadow',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'ea1fce5eb7d945b483c3568308d4d3c3',
    name: "Changer l'opacité d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '0dfb964f6b2b4a7fa753a6d12513717b',
    name:
      'Utiliser la propriété text-transform pour mettre un texte en majuscule',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '3e7ace9739ce4e96b15ba01178fe8411',
    name: 'Changer la taille de police des titres',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'c9980b94a2534c288d4876f2387293ff',
    name: 'Changer la graisse des titres',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '9c2d4de67d4945c0adc5693f7fb9b5ed',
    name: 'Changer la taille de police des paragraphes',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'a186b46abbec4abdbfd0dd8ec3410b53',
    name: "Modifier l'interligne du texte",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'ef1bd97788a74373ac5303d3a4507b9c',
    name: 'Changer de style au survol de la souris',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'f0ac4ea498f34b5cb580cbe2af072adc',
    name: "Changer la position relative d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '22fa151a75934155afb70ce462c3cdac',
    name: 'Déplacer un élément avec les offsets',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '61d687f45ffd4c7689143ba40e9458b7',
    name: 'Positionner un élément en fonction de son élément parent',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '83725c6dda7b44ea90ddb8ba65971d0a',
    name: "Positionner un élément en fonction de la fenêtre d'affichage",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'a5db80fb87994ab892966f2014927a1d',
    name: 'Déplacer un élément à gauche ou à droite avec float',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '66d35f62195e41028c23e8b5ee593758',
    name: "Modifier l'ordre d'empilage des éléments avec z-index",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '4bcadc844986478fbbc124cb833e910e',
    name: 'Centrer un élément horizontalement avec margin',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'b1f7567eff524693943b32ff276de954',
    name: 'Comprendre les couleurs complémentaires',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '5a07246cd20f40ffb204c905eee3e51e',
    name: 'Comprendre les triades de couleur',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'e74780e3f4e94a9db4f9ea00a8aad56f',
    name: 'Modifier la couleur de plusieurs éléments',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'cd6bcfb3c09047839014c2e585f5601f',
    name: "Ajuster la teinte d'une couleur",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'ed390abbbf43492aaa452fd2ccccd860',
    name: "Ajuster la saturation et la luminosité d'une couleur",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'a75b5d110b20456ca4701c1ba5995321',
    name: 'Créer un dégradé linéaire en CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '3e7845139cac4ce2897c096cb7170344',
    name: 'Créer des rayures avec les dégradés CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'de984c5dd3c8498db7aa547072fd6661',
    name: 'Créer une texture en utilisant une image',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'd240dc6bf6c94a708d4de5c6b46c5514',
    name: "Changer la taille d'un élément avec la propriété transform scale",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '7b75f8a4c5d948d499b6269a34139b32',
    name: 'Utiliser la propriété transform scale au survol de la souris',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '0deefcd8c25b451f9322cda1c7656f9d',
    name:
      "Utiliser propriété transform skewX pour obliquer un élément sur l'axe X",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'f36cbd56aa3e48289e38bda1c7e7dd48',
    name:
      "Utiliser propriété transform skewY pour obliquer un élément sur l'axe Y",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '4c9f083d039341b29196f0b8248d7d1b',
    name: 'Créer une forme en CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '93188e1268ff44e9b7da1312c9946760',
    name: 'Créer une forme plus complexe en CSS et HTML',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '4f1e65fb1c8b4052a8f3c6613e6abc12',
    name: 'Comprendre comment les animations fonctionnent en CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '8fb7ae2bf74348f8bfc4a11520292429',
    name: "Utiliser une animation pour modifier l'état d'un élément au survol",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '44529074c72a4ea48684433a319a6f65',
    name: "Modifier le fill mode d'une animation",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '5664cb174f56491996b3ac93a291d105',
    name: 'Créer du mouvement avec les animations CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '9ea4000914d54297872f3ad699554bd8',
    name: 'Donner une direction visuelle en faisant disparaître un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '2895471729dd42ee8b4febcdfbf09a6b',
    name: 'Animer un élément continuellement avec infinite',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'f663460acb3348f587cab39f02a6d905',
    name: 'Faire battre un cœur avec les animations CSS',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'bdb60a7468324aeb9d7b9af5d34a14f6',
    name: 'Animer des éléments à différentes vitesses',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'edd32cc2a3be442fbb58a43283e5c7f6',
    name: 'Animer plusieurs éléments à des vitesses variables',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '509514ac620b4b6089b708fc4c7eec83',
    name: "Modifier l'animation timing avec un mot clef",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '36a4c62532114716a0d912ddd6b7d76a',
    name: 'Comprendre comment fonctionnent les courbes de Bézier',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: 'cd221fab59b44bc69c5f2219a421cfcf',
    name: 'Utiliser une courbe bézier pour déplacer une forme',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '215ba028562b47e4a709243c238f4feb',
    name: 'Rendre une animation plus naturelle avec les courbes bézier',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '55eddb0a637a46238c3de1af581afcee',
    premium: true
  },
  {
    id: '64a42f76920440f8804c3c1d8b9d6642',
    name: 'Créer une Media Query',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '44f6d723df7646eab9b165679aeae67b',
    premium: true
  },
  {
    id: '1775fb8f05434f4ca8fff16d7c8beb5e',
    name: 'Rendre une image responsive',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '44f6d723df7646eab9b165679aeae67b',
    premium: true
  },
  {
    id: '251d9b658d504b6282127c53e4b6aea8',
    name: 'Utiliser une image retina pour les grands affichages',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '44f6d723df7646eab9b165679aeae67b',
    premium: true
  },
  {
    id: 'fe2c12c1155f4a2eb0112f23f3d2dcc1',
    name: 'Rendre le texte responsive',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '44f6d723df7646eab9b165679aeae67b',
    premium: true
  },
  {
    id: 'd93e7d6f12c64fc2ad8929738db2ed91',
    name: 'Utiliser display: flex pour positionner deux éléments',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'bfc91da2288b4d01a66a22816f8a7a00',
    name: 'Ajouter flexbox à un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'fdf861755ff844ee937cf155003096e7',
    name: 'Utiliser la propriété flex-direction pour faire une ligne',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '83307a1f859640fbb1196262d96df9f8',
    name: 'Appliquer la propriété flex-direction pour créer une ligne',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'b1c34cbf4cb44661b133600d2524ccb7',
    name: 'Utiliser la propriété flex-direction pour faire une colonne',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '961dbb1308f147b190c974ac5a02d901',
    name: 'Appliquer la propriété flex-direction pour créer une colonne',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '0f02870e14c84440b5acf73ddbeffda0',
    name: 'Aligner des éléments avec la propriété justify-content',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '27249ee867604d149dc23202afdf6abd',
    name: 'Utiliser la propriété justify-content',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'b83562d5602e42a2a29332d3d5eef3de',
    name: 'Aligner des éléments avec la propriété align-items',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'f19f923029074714bcdd48cda4ffe8e5',
    name: 'Utiliser la propriété align-items',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '86c78121bbfd4d5bb27f93d6001d4b78',
    name: 'Utiliser la propriété flex-wrap pour envelopper des éléments',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '3a76bc9a1adc4300ba7e0aec54d9048c',
    name: 'Utiliser la propriété flex-shrink pour rétrécir un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'eba8abbdf4024d9fb151d3ff05f83961',
    name: 'Utiliser la propriété flex-grow pour agrandir un élément',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '5b0f994891c3476ca25dc5fec6d4cf4f',
    name:
      "Utiliser la propriété flex-basis pour paramétrer la taille de base d'un élément",
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '381b7d5a36aa4afcbe00a04ad9d521eb',
    name: 'Utiliser la propriété flex raccourcie',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'f7c7274ea82f4418ba97950dff07c833',
    name: 'Utiliser la propriété order pour réarranger les éléments',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: '1611ca9036264bd99ef44e8915e40a75',
    name: 'Utiliser la propriété align-self',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '9b52e15528194f47854757deb85869b7',
    premium: true
  },
  {
    id: 'a8a35ea406394e0d8e9f5f61cb5a24ba',
    name: 'Créer une CSS Grid',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'e9dea1f9d7f1442a9d5f252df0dcf5e0',
    name: 'Ajouter des colonnes avec grid-template-columns',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'f25db76c8fbf45c983e0e15db68cd913',
    name: 'Ajouter une ligne avec grid-template-rows',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '1982c6c68d364b60a3aea7b5ac03c3a3',
    name:
      'Utiliser les unités de CSS Grid pour changer la taille des colonnes et des lignes',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '6b52ae85eec144639bc6e81b142cd186',
    name: 'Créer un écart entre les colonnes avec grid-column-gap',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '8d1e56b4e94f4bbaa9f84036af4dc897',
    name: 'Créer un écart entre les lignes avec grid-row-gap',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'e6b35c4592984f338a0753f7751fa5a0',
    name: 'Ajouter un écart plus rapidement avec grid-gap',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '06ed00f270a34bdb8ec1c62c559d3e7d',
    name: 'Utiliser grid-column',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'ffafb779f9e64eb0944d35778e7a140d',
    name: 'Utiliser grid-row',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '4928abe3afad439e92ec2da15eeaf513',
    name: 'Aligner un élément horizontalement avec justify-self',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '570e2cb9811844f296f944cd5fce1fa3',
    name: 'Aligner un élément verticalement avec align-self',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'e43669a983e34617b6459b1126668cef',
    name: 'Aligner tous les éléments horizontalement avec justify-items',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'cd1e418cb3a041d296bd18392f7986a7',
    name: 'Aligner tous les éléments verticalement avec align-items',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '8b1c1205f6924903a0d992fee1b71cad',
    name: 'Diviser une grille en plusieurs zones',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '388b62368c73419da904d83cbfa20ad0',
    name: 'Placer des éléments dans la grille avec la propriété grid-area',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'de4f22c84bd34e07856c406890794b5b',
    name: 'Utiliser grid-area sans nommer de zones',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '3844e6a29c364bd7abe88cd43c8db5f0',
    name: 'Utiliser la fonction repeat',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'ba7d7efd9c054104b5db517c1b127678',
    name: 'Utiliser la fonction minmax',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '8f9e365b27ef42d2b21fe7a02a124b9e',
    name: 'Créer des layouts flexibles avec la propriété auto-fill',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'd2133b371fd84a62810ce965b37f2d3b',
    name: 'Créer des layouts flexibles avec la propriété auto-fit',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: '7a2cf49300b04e8abc63bcf0efd0ce75',
    name: 'Utiliser des Media Queries pour créer une grille responsive',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'f8f06692dd11420082d56d7f4b5be88d',
    name: 'Créer une grille dans une grille',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: '73410e97d5fe40318f0979b455cb55ae',
    premium: true
  },
  {
    id: 'cert1',
    name: 'Comment valider la certification',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: 'd40cb1bfdc4d4c4098c08659175a56cd',
    premium: true,
    video: 'CbqmUB_ZZTk'
  },
  {
    id: '06db5dc16ef94222975bf8e8b5f33155',
    name: 'Créer une page de vente',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: 'd40cb1bfdc4d4c4098c08659175a56cd',
    premium: true,
    video: 'ovGKQ1dlQ3E'
  },
  {
    id: 'eec445928a0e4548b65bd05d96a8ad9e',
    name: 'Créer un quizz',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: 'd40cb1bfdc4d4c4098c08659175a56cd',
    premium: true,
    video: 'OA7riLgI8WQ'
  },
  {
    id: 'f78dfd27068d43e1b32e4535aea08170',
    name: 'Créer une documentation',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: 'd40cb1bfdc4d4c4098c08659175a56cd',
    premium: true,
    video: 's5rau839eyo'
  },
  {
    id: '4f92c666506f4810b77fe17f70d83718',
    name: 'Créer un site pour restaurant',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: 'd40cb1bfdc4d4c4098c08659175a56cd',
    premium: true,
    video: 'wuUtonmTvOg'
  },
  {
    id: '26d347a51eb243dd80c314a56615998f',
    name: 'Créer ta page portfolio',
    date: 1627250400000,
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    chapter: 'd40cb1bfdc4d4c4098c08659175a56cd',
    premium: true,
    video: 'oOl9-vywszo'
  },
  {
    id: '54d02f0b6a5c49bdaaba2b4fbeea760b',
    name: 'Commenter ton code JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'codaa4c38813826956a2e80ba'
  },
  {
    id: 'cdd49d0aa59e4b518db6d1d54ec5d0fa',
    name: 'Déclarer les variables JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co9f344d6bef5e1d0b8d3dfcd'
  },
  {
    id: 'c1de9a2de3ea452a952ca9b76cab0cd8',
    name: "Stockage de valeurs avec l'opérateur d'affectation",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cocb6489b9777e94ae399f6fd'
  },
  {
    id: 'beec2de9f9a04a96b766d7e7026eac70',
    name: "Affectation de la valeur d'une variable à une autre",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co2634336ad9e703eb3e3968b'
  },
  {
    id: '4274f35563af422c9df69290ee54dacb',
    name: "Initialisation des variables avec l'opérateur d'affectation",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cobe94dc8a5b9e21229740856'
  },
  {
    id: '981e7538141d4e53aff35480f24ed75b',
    name: 'Comprendre les variables non initialisées',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co6a8457bb949f119f6a0e28b'
  },
  {
    id: 'a7bc747ef1f9436191d5a411766b2e16',
    name: 'Comprendre la sensibilité à la casse dans les variables',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cod704926b2b228509fbefd04'
  },
  {
    id: '0375f042e014445e92be19d32002b4c6',
    name: 'Ajouter deux nombres avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co5fc42e5a74b74e8a0af5778'
  },
  {
    id: '1be1ef8bf7b64d1e85d81a6b0a8eab9d',
    name: "Soustraire un nombre d'un autre avec JavaScript",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cod604c4c80aceefea8657c3b'
  },
  {
    id: '209768a9faad493e8baab8a135857ba2',
    name: 'Multiplier deux nombres avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co82b4aba86b0dce734c6b43b'
  },
  {
    id: '5f66bd33fdba425e87ea8bd8caa3cb98',
    name: 'Diviser un nombre par un autre avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co0044f5894575254d73f79ac'
  },
  {
    id: '03ddee84438d4a23bec42bfe94fbf32f',
    name: 'Incrémenter un nombre avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc85430b94cf0853b3b50531'
  },
  {
    id: '9e056b5aaca3436d93227ec6579fe39d',
    name: 'Décrémenter un nombre avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'code647b89c0729295bfc86f6'
  },
  {
    id: '79105a19f4f6497fa8ee090fb92e79e4',
    name: 'Créer des nombres décimaux avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co46a4cdea101171fc666fbc2'
  },
  {
    id: '00ddbd6ddda247338afd2d9ca54d1867',
    name: 'Multiplier deux décimales avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cob0245dd91eacd350176085e'
  },
  {
    id: 'f99383dd527a4b8b938ef6897dbb25c5',
    name: 'Diviser une décimale par une autre avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co07d4628957fe893d0c0aadb'
  },
  {
    id: '9235e3a7a799451d82d430b244b2a2d1',
    name: 'Trouver un reste en JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc3b412f8f3bf0d446dea808'
  },
  {
    id: 'e2ac9573223b41a796e2115fc49a9d58',
    name: 'Affectation composée avec addition augmentée',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co60b4c218fc2daa8e0e3c4f6'
  },
  {
    id: 'b4f3a18302e043e48eac94109dd103dd',
    name: 'Affectation composée avec soustraction augmentée',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cod704b4e986a598fcaa4c627'
  },
  {
    id: '6e2eb576acb746bcbc6f7ad41234a871',
    name: 'Affectation composée avec multiplication augmentée',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co7b046c48e9920646f6e037d'
  },
  {
    id: '1de6d9266f8744e09cd503e6dde3ee32',
    name: 'Affectation composée avec division augmentée',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co25048b896c4002174695e98'
  },
  {
    id: 'fc20b314238243dd9fb2b918a352cddc',
    name: 'Déclarer des variables de chaînes de caractères',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co2c5462795036b8f8d57c2a3'
  },
  {
    id: '2f216cb43fd94441a77d4e40327408fd',
    name: 'Échapper les guillemets dans les chaînes de caractères',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co8774b76bc966ef5a7cfbd4c'
  },
  {
    id: 'cde8088658f1472a8c0a786bb304855e',
    name: 'Utiliser des apostrophes pour déclarer une chaîne de caractères',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cod14462aac6f93d9a46e4241'
  },
  {
    id: '625cf402e46a468ba1e32164db30e404',
    name: "Séquences d'échappement dans les chaînes",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cobf44a5f978c9b88d93b53a0'
  },
  {
    id: 'd893fe05ea4c45e7b6864122ac935c71',
    name: "Concaténer des chaînes avec l'opérateur Plus",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co2664accb5e7ce3044aff10a'
  },
  {
    id: '2bfb2dab060a4608b5a0c8c919044915',
    name: "Concaténer des chaînes avec l'opérateur Plus Égal",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co38a454fb8bc0ac78cff620f'
  },
  {
    id: '56caa0c2a7054ce29f6c85bde11f4661',
    name: 'Construction de chaînes de caractères avec des variables',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co4de49f787b46267170ffaed'
  },
  {
    id: 'b2e5a3b7a63942b5b1571bf52ed083f7',
    name: 'Ajout de variables aux chaînes',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co75b467a856509d2ee178e7c'
  },
  {
    id: 'fc6271efa1c64730ae7de6795417c8bd',
    name: "Trouver la longueur d'une chaîne",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co1e745ee87384d88d2fdad54'
  },
  {
    id: 'ccff4644e8494016aa937e4a3336e33e',
    name:
      "Utiliser la notation entre crochets pour trouver le premier caractère d'une chaîne",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co68f434c8d82d53c1ceb405d'
  },
  {
    id: '8eefba1cd3994b47a15a98f168238e35',
    name: "Comprendre l'immuabilité des chaînes de caractères",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co7084a2d8295e915e55aed89'
  },
  {
    id: 'c97d57653d854b59b5ee08a9a543da5a',
    name:
      'Utiliser la notation entre crochets pour trouver le nième caractère dans une chaîne',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cocea435fb12039829bd2ff19'
  },
  {
    id: '756339370c5b4fb08b9757e8f33db8ad',
    name:
      "Utiliser la notation entre crochets pour trouver le dernier caractère d'une chaîne",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co00144c0824222991ec39f1a'
  },
  {
    id: 'b51e1b3a27404383bfde856e5a0bcd1a',
    name:
      "Utiliser la notation entre crochets pour rechercher l'avant-dernier caractère d'une chaîne",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co6754a6384e060c1d374d1b0'
  },
  {
    id: '4df826a7f58b44a9a202be1a47fb6975',
    name: 'Textodingo',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'codc04215ab9a343a4b2ed14a'
  },
  {
    id: '13f48549a51f4aa49353c4c0e360206c',
    name:
      "Stocker plusieurs valeurs dans une variable à l'aide de tableaux JavaScript",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co1b745c59050bd1dff35908c'
  },
  {
    id: '3a8ddd2e697c4f938edf0a77695e8dca',
    name: 'Imbriquer un tableau dans un autre tableau',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co1944dc896e6612f9284bd01'
  },
  {
    id: '5f064044674244479738013a70f2051a',
    name: 'Accéder aux données de tableau avec des index',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coa6e40fba4a14e808842b8fe'
  },
  {
    id: 'e87e61303f834f2b9e0a06f33229ca50',
    name: 'Modifier les données du tableau avec des index',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co249407c82e4f04bc6ae0ca1'
  },
  {
    id: 'bd84eb2b8737413baa5e96f856877bde',
    name: 'Accéder à des tableaux multidimensionnels avec des index',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co21d48ef8ec3b06081a52cee'
  },
  {
    id: '1468dc72b9de472a9b2a528b79c42af1',
    name: 'Manipuler des tableaux avec push()',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coee447a289fcfbc4b288270d'
  },
  {
    id: 'c87d5712f27b44aab65220904f0acc36',
    name: 'Manipuler des tableaux avec pop()',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co035410f8621f65093cafce9'
  },
  {
    id: '1cd90ca054b3485e9ba7f6096fce23a6',
    name: 'Manipuler des tableaux avec shift()',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co4f9449994730d666acb499b'
  },
  {
    id: 'ab6487fa2095482196564d5d28fb0d32',
    name: 'Manipuler les tableaux avec unshift()',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co415408baaa02a1b994e8a36'
  },
  {
    id: '86b4411f9b79434c876ee98c00400a26',
    name: 'Liste de courses',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co0a74c819fe8dfb3546d50fd'
  },
  {
    id: '7f92b00db2e442a39e1fae00b81fc20b',
    name: 'Écrire du JavaScript réutilisable avec des fonctions',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cob184957837567194c687ee2'
  },
  {
    id: 'edc7e5ffb4ec4453a56c8ba2f8d59219',
    name: 'Passer des valeurs aux fonctions avec des arguments',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co20240339760db6a432e7b71'
  },
  {
    id: '84b9e8a41e7a4091af93bc83d9691d06',
    name: 'Portée globale et fonctions',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cob114312843b84da73b2fa2b'
  },
  {
    id: 'a69932d328fe4cdeb6a738d8f911a042',
    name: 'Portée et fonctions locales',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co4ca401aaadddc13bc2a56f3'
  },
  {
    id: 'b4c13c62ed3d43779717860997916c71',
    name: 'Portée globale vs. Locale dans les fonctions',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co69e43e18098329b90ae54e6'
  },
  {
    id: '5c007ae631114506a66cef6f609c26bc',
    name: "Renvoyer une valeur à partir d'une fonction avec Return",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc77430284c93c9f05f57638'
  },
  {
    id: 'aeb8c826567f46f4aa6d797ebc4da085',
    name: 'Comprendre la valeur non définie renvoyée par une fonction',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coac44dc289730e0963780be6'
  },
  {
    id: '4a194d6ac35e4390b6adbb2370230204',
    name: 'Affectation avec une valeur renvoyée',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cobfe4185add9e5b5190769c6'
  },
  {
    id: '949afcaf155d449d8e16f7f80b784ab9',
    name: 'Faire la queue',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co32340ff999b3a0c64d5a224'
  },
  {
    id: 'e24f00efd5b94941a313b2b6f173b8eb',
    name: 'Comprendre les valeurs booléennes',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co769427abfbeb4ea731b90fc'
  },
  {
    id: '462249d29106404e84b0e204cc4dd68f',
    name: 'Utiliser la logique conditionnelle avec les instructions If',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coed648e68f804c77dc7db54d'
  },
  {
    id: '5f026923b38a4327a929fc6393cfffea',
    name: "Comparaison avec l'opérateur d'égalité",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coabe43f08868b91000288555'
  },
  {
    id: 'ed6dbe303f814088968598709dc90c8d',
    name: "Comparaison avec l'opérateur d'égalité stricte",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc2e4679a29b94ce29630927'
  },
  {
    id: '88af90b1992a41d9aa670d0e29f83a5f',
    name: 'Entraîne-toi à comparer différentes valeurs',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co6e54259a5852a50d35b9e02'
  },
  {
    id: '428b63d13c90481d84393e5795b48669',
    name: "Comparaison avec l'opérateur d'inégalité",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cof35462c96df178618ba1be8'
  },
  {
    id: '791e5f6fc3aa443abc7eac031a159ccc',
    name: "Comparaison avec l'opérateur d'inégalité stricte",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cob1541f996b670a523da6cc5'
  },
  {
    id: '50abded4484b46fd94da398f9dd5b1b2',
    name: "Comparaison avec l'opérateur Supérieur à",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coaac4c74906e86de0e0d9a87'
  },
  {
    id: '3d99f15c010a40deb37a7a62947309b0',
    name: "Comparaison avec l'opérateur Supérieur ou égal à",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co47943fcabd1164d6e23ca76'
  },
  {
    id: '9974971cf3cd49c0acb81f5fd07cb02e',
    name: "Comparaison avec l'opérateur Inférieur à",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coe4a43e2be04affd68b4d5c1'
  },
  {
    id: '214fce5280ec444696cdb879f0ed9603',
    name: "Comparaison avec l'opérateur Inférieur ou égal à",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co811490b898ec20408b4ccb4'
  },
  {
    id: '58a1ccff5a44440f8ab2ae2d1911a1d3',
    name: "Comparaisons avec l'opérateur logique ET",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc3f4e2b9dc51f1cf0aa22b5'
  },
  {
    id: 'f4d34d0d097b40cc9e181e9848a3e96e',
    name: "Comparaisons avec l'opérateur logique OU",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co5e441de8b12ce903ef53778'
  },
  {
    id: '412d161fad54405c99ed08bf4422f43b',
    name: 'Présentation des instructions Else',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co74f480297bf6a9909ea2b61'
  },
  {
    id: '3a0c6001b27c452083710a8af29e6df5',
    name: 'Présentation des instructions Else If',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cof014b1782ead85f4b67c03c'
  },
  {
    id: 'dcf126470be84c5787ddef1126475653',
    name: 'Ordre logique dans les instructions If Else',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'codab436da8344732d11f6bb8'
  },
  {
    id: '9214d37f3d394131a91c6f4befde6fe2',
    name: 'Enchaînement des instructions If Else',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cof704f9dbb6cde34aa131de8'
  },
  {
    id: '8d8a81121bef44668b0cce7d04d8c113',
    name: 'Code du golf',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co45246279f4ba79d9b2e1258'
  },
  {
    id: '75dce7690bdf4914a829a009d76f1569',
    name: 'Sélection parmi de nombreuses options avec les instructions Switch',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co0604ee8a6554c5cfe474c7b'
  },
  {
    id: '80d7da3bbace49c7b2032d5d16f146c3',
    name: "Ajout d'une option par défaut dans les instructions Switch",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co17142708567328831026a6e'
  },
  {
    id: 'e34031ffbfec4400bd792a77ad69e2e1',
    name: 'Plusieurs options identiques dans les instructions Switch',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coff542eda031a0746b0bb9bd'
  },
  {
    id: '0c351d3df06a4e8a83ef495baecf1c66',
    name: 'Remplacement des chaînes If Else avec un Switch',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co5184e01804d24e9f31c1cec'
  },
  {
    id: '196b28ff51de4d6a8006b2827786fd94',
    name: 'Renvoyer des valeurs booléennes à partir de fonctions',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co74d4dd383ac011d4920d8eb'
  },
  {
    id: 'ec7b75a6036c462382e9d2bd60a20f81',
    name: 'Retour rapide dans les fonctions',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co3d149d9951c7346717d5d23'
  },
  {
    id: '9a67f3a7a0d54a0eaa502ffdadf668a4',
    name: 'Compter les cartes',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cod1e4e08938633a8d092e482'
  },
  {
    id: '4a16a420263e4f26aa3588685bf6d1af',
    name: 'Créer des objets JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc8f413a803263d38dee057c'
  },
  {
    id: 'a9ac16e927a946d4b81d38d555f004a5',
    name: "Accès aux propriétés d'objet avec la notation par points",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coe4647908b264d0375c65003'
  },
  {
    id: '42f848d4145743939044afd520bd3569',
    name: "Accès aux propriétés d'objet avec la notation entre crochets",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co780483f83a2f7c1f671994d'
  },
  {
    id: 'fcc67db2e3204458921b5b95188a897b',
    name: "Accès aux propriétés d'objet avec des variables",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co0494322b8abdfedd9bcadc4'
  },
  {
    id: '16e5d1c467dc49aeb972872070355281',
    name: "Mise à jour des propriétés de l'objet",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co4c945eb8b55bc6c240e0470'
  },
  {
    id: 'ab48ef7e901340d7ab285341907138eb',
    name: 'Ajouter de nouvelles propriétés à un objet JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co9184043aa504909c0f269a4'
  },
  {
    id: '1c5093c9c10f40b694b7377bb66ce291',
    name: "Supprimer les propriétés d'un objet JavaScript",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co8fe46b6b8e7e5f210634696'
  },
  {
    id: '02553f55583f46709dcd39f40fffc61e',
    name: "Utilisation d'objets pour les recherches",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co0f0475b9a925dee3b8a0074'
  },
  {
    id: '47e5cb9eb360468791a4644caac4e708',
    name: 'Tester les propriétés des objets',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co2fa48e8a99525a8e89c73fc'
  },
  {
    id: '235bfbe1a99f4e63ba0ca23e074d2461',
    name: "Manipulation d'objets complexes",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cob6e43158da5bebffbd08cf7'
  },
  {
    id: 'a80b187e155447178d4efc94ffc9c0df',
    name: 'Accès aux objets imbriqués',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co1424a0baa01479faf760395'
  },
  {
    id: 'de19b3fbdd014bbe942170cac6ff6ee7',
    name: 'Accès aux tableaux imbriqués',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co97946ec96d137cbc6e348b6'
  },
  {
    id: 'd78e5b8b2ebb4bfa8e40483a91d5bd10',
    name: 'Collection de disques',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc5d4976b2385df9090b15c0'
  },
  {
    id: '3b3ce6ae299d44319b323f879827bace',
    name: 'Itérer avec les boucles While de JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co9fc449e8983c52401556436'
  },
  {
    id: '320aeda51e24401da79bada9bb20e6fd',
    name: 'Itérer avec la boucle For de JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co66c44d0a2a9d1bc8d134091'
  },
  {
    id: '757e0773254f45b28c21dc22a2b0b4e6',
    name: 'Itérer les nombres impairs avec une boucle For',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cof254e3c84fb21555a4a6b95'
  },
  {
    id: '1ce5a46f7e0d4d2faec6df5c50935f3f',
    name: 'Compte à rebours avec une boucle For',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co6664cd2b011096b9bf82223'
  },
  {
    id: 'e7d78c308e1742b78231f83a72099997',
    name: 'Itérer dans un tableau avec une boucle For',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co6bb4ed181e782d1ae49f9e1'
  },
  {
    id: 'a647b68389d4484da3da32816b77bb92',
    name: 'Imbrication de boucles',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cofd74339b1ff5a1cb52f3b80'
  },
  {
    id: '937d1802a501453abb0726afec035b7c',
    name: 'Itérer avec la boucle Do...While en JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co37f4ca686d2e08501a0d179'
  },
  {
    id: 'fb87b9e86e0e436ea2b1251c233154ca',
    name: "Remplacer les boucles à l'aide de la récursivité",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coa4743a2b564d608b89a30c1'
  },
  {
    id: 'bb0de9a60d6549b7ac97194e01eebc98',
    name: 'Recherche de profil',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coa584f94a3a8c2f9db8f4386'
  },
  {
    id: 'ada7574fd64b4a079798c1886281d293',
    name: 'Générer des décimaux aléatoires avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coda04672b60f1657e97ac305'
  },
  {
    id: '48c2aecc34604f508c6ad8d441a461dd',
    name: 'Générer des nombres entiers aléatoires avec JavaScript',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'cod304a69a5c84f353fc66bc3'
  },
  {
    id: 'a6f3005bd0cc4ecbb6eff9b8983ca8ed',
    name: 'Générer des nombres entiers aléatoires dans une plage',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co6d140449f09a07a29461f2d'
  },
  {
    id: '7e71964184c44223b5193e21fa33573a',
    name: 'Utiliser la fonction parseInt',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co5b34eecbe548a49b3c57663'
  },
  {
    id: 'd7bc6dc5985246ce8e073869b4370502',
    name: 'Utiliser la fonction parseInt avec une base',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co01c473fa5f53a826cee17b1'
  },
  {
    id: '22069fc11dde48858bb69135fd274740',
    name: "Utiliser l'opérateur conditionnel (ternaire)",
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coe6f40dfb601581954ddf47e'
  },
  {
    id: '33e9c92a0db34186b65511010759d511',
    name: 'Utiliser plusieurs opérateurs conditionnels (ternaires)',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co16043eda9f5f161012acb9e'
  },
  {
    id: '00ce08d2e3a243c0aae338c31b2ef507',
    name: 'Utiliser la récursivité pour créer un compte à rebours',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'coc0f4cde923937316aeb0964'
  },
  {
    id: '90196a35599c4fa58662f414b3c032bb',
    name: 'Utiliser la récursivité pour créer une plage de nombre',
    date: 1631224800000,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: 'ed3b2eba9b8c40bc94732f42144e3e95',
    premium: false,
    scrimba: 'co039442a8d311bd9f307cfeb'
  },
  {
    id: 'b16cb6d2b80f496a92c7c510b87ab312',
    name: 'Explorer les différences entre les mots clés var et let',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'b725ff74f0bd465187bdbe136a8484a3',
    name:
      "Comparer les champs d'application de la var et laissez les mots-clés",
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '16bfdcaf17834d759e165252ee717aa4',
    name: 'Déclarer une variable en lecture seule avec le mot-clé const',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '3de4329ec57a45a08b93c3a3912bee67',
    name: 'Muter un tableau déclaré avec const',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '3cbc986155be4c3397f4d43677b9cc28',
    name: "Empêcher la mutation d'objet",
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '5d7ec2ecf0cd47b5848da0a4b55652bb',
    name:
      'Utiliser les fonctions fléchées pour écrire des fonctions anonymes concises',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '77305630cda64a02bf26bd0e2d1e9961',
    name: 'Écrire des fonctions fléchées avec des paramètres',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '5924acb7e6ed443c8d8095af6e42652c',
    name: 'Définir les paramètres par défaut pour tes fonctions',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'b974ed3e6e264254abf195aaf7a058e6',
    name: 'Utiliser le paramètre Rest avec les paramètres de fonction',
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'bbd9ab1b527344c484be93311dd12f23',
    name: "Utiliser l'opérateur Spread pour évaluer les tableaux sur place",
    date: 1634657983575,
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '3679cb7bdd194ca8b35e3dcb3c75d924',
    name:
      "Utiliser l'affectation déstructurante pour extraire des valeurs d'objets ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'a8f784e78d6943ee8a9d2bdec3e40a8a',
    name:
      "Utiliser l'affectation déstructurante pour affecter des variables à partir d'objets ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '3f52806c99244d81b544bded4b8730fd',
    name:
      "Utiliser l'affectation déstructurante pour affecter des variables à partir d'objets imbriqués ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '443a7e0929c8493381749873114d4722',
    name:
      "Utiliser l'affectation déstructurante pour affecter des variables à partir de tableaux ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '3d2c6fde6ed64d599714ba313d030ed1',
    name:
      "Utilisez l'affectation de déstructuration avec le paramètre Rest pour réaffecter des éléments de tableau ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '4fbb10bc69bf4f7a93f9ca7436b83909',
    name:
      "Utiliser l'affectation déstructurante pour passer un objet en tant que paramètres d'une fonction ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'db9ceb6f76f546479b316d31b5620dd2',
    name: "Créer des chaînes à l'aide de littéraux de modèle ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'c2d9b6bb174643be8a7f9e444c03a9e8',
    name:
      "Écrire des déclarations concises d'objets littéraux à l'aide d'un raccourci de propriété d'objet ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'f74b3a58f1434521987885ebacb2ec71',
    name: 'Écrire des fonctions déclaratives concises avec ES6 ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '04c8419232e143a9883043ced0c337e5',
    name:
      'Utiliser la syntaxe de classe pour définir une fonction constructeur ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'd115890547a34fdb85cd410cf1c23146',
    name:
      "Utiliser des getters et des setters pour contrôler l'accès à un objet ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '6bece4fac6374c03bf0fffbf8dd6ba21',
    name: 'Créer un script de module ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '7bd0b54c68f04bd486f3f63cf4c4a883',
    name: "Utiliser l'exportation pour partager un bloc de code ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'b06120041041484bac53946f54b0eb54',
    name: "Réutiliser le code JavaScript à l'aide de l'importation ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '4f317e3de3fa4a88af90535372e3fe54',
    name: "Utilisez * pour tout importer d'un fichier ",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'c9622b4a2ce740a59f6b5d0d616c313a',
    name: 'Créer une exportation de secours avec exportation par défaut ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: 'fee15cd4a7f342d683e34684188daf54',
    name: 'Importer une exportation par défaut ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '2acc7dfbc0b7461ab1ae73215266a098',
    name: 'Créer une promesse JavaScript ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '634872e1ae5247389de725abf5857d40',
    name: 'Remplir une promesse avec détermination et rejeter ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '810a87cbeea24ee1965da9d1377c5762',
    name: 'Gérer une promesse tenue avec then ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '49cd8d08a6324c1e9778346096eba0ed',
    name: 'Gérer une promesse rejetée avec catch ',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '1c8a36a1ca5040a3b073527e151fa134',
    premium: true
  },
  {
    id: '584ff28f196c4452827b114bfa7cbbbd',
    name: 'Utiliser la méthode test()',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '4102c561f150427abb135fb0beffdb4c',
    name: 'Faire correspondre à la chaîne littérale',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'c223835bbe394e80994b19f390b35dec',
    name:
      'Faire correspondre une chaîne littérale avec différentes possibilités',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '9a96e003b11a4ef5bb471df15b954f46',
    name: 'Ignorer la casse lors de la correspondance',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'e2c06700d82e4ab9966153cbfbc8918a',
    name: 'Extraire les correspondances',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '41de08c774264295a77cd0e51b9d3edf',
    name: 'Trouver plus que la première correspondance',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'a794d4887efc44a5a9baf21ccb8a958e',
    name:
      "Faire correspondre à n'importe quoi avec une période de caractère générique",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '78bfba9bcf194e80ad37d6980bbf285c',
    name: 'Faire correspondre un seul caractère avec plusieurs possibilités',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '3bd4ea015a654bde97dbb732930e23ee',
    name: "Faire correspondre les lettres de l'alphabet",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'dd9b92c7d97e4343bd55246acc68e477',
    name: "Faire correspondre les chiffres et les lettres de l'alphabet",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '8cff7ac733eb49029d734765bf0acd27',
    name: 'Faire correspondre des caractères uniques non spécifiés',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'c3d02a2d091944d8aa4ca312224e158a',
    name:
      'Faire correspondre les caractères qui se produisent une ou plusieurs fois',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '1036e29852b14ceeab6b95092cdd0278',
    name:
      'Faire correspondre les caractères qui se produisent zéro ou plusieurs fois',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'a7983cc6984f48a4a6ff0fd1e93b56d2',
    name: 'Trouver des personnages avec la correspondance paresseuse',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '89336007fbf246179439226659a42e96',
    name: 'Trouvez un ou plusieurs criminels dans une chasse',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'b6d1610abcce4661ae4b24ae8ac4d170',
    name: 'Faire correspondre les modèles de cordes de début',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '9a89e093e3df4d7b8367468bf92ed8a7',
    name: 'Faire correspondre les modèles de chaîne de fin',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'd4025c4f7e724236b5046e3a60d3e76c',
    name: 'Faire correspondre toutes les lettres et tous les chiffres',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '02624b58ebc5407f8ef1b26f9a55a2f7',
    name: 'Faites correspondre tout sauf les lettres et les chiffres',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '4e6334fa15f3438cabc997983bc05ed4',
    name: 'Faire correspondre tous les nombres',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '2055f2eeb5394646a0ef71dc7ac4e12a',
    name: 'Faire correspondre tous les non-numéros',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'f9ef8cb4b8ee4eba9f81cf1790faa239',
    name: "Restreindre les noms d'utilisateur possibles",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '96d496829fef466b8158fdb21c43c6cd',
    name: 'Faire correspondre les espaces',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '1836988c8b4a44298a50419475d0845d',
    name: 'Faire correspondre les caractères non blancs',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'e83066b362e04ea59c94f8798511d541',
    name: 'Spécifiez le nombre supérieur et inférieur de correspondances',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '0c30a35559ca48b69201a33f549edc0f',
    name: 'Spécifiez uniquement le nombre inférieur de correspondances',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '3094e2c400d740b3869391571b56edc2',
    name: 'Spécifiez le nombre exact de correspondances',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'a64c9256b04d4fc1accc9af9f69ca02e',
    name: 'Vérifier tout ou rien',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'd6d1ee65d0ea4ab7b42beb084da023c7',
    name: 'Anticipation positive et négative',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '21bf6cc47f8b4df49837ec16c1fb0891',
    name: 'Vérifier le regroupement mixte de caractères',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'ce29bf875c04496b8aa9a5ba8851cef8',
    name: "Réutiliser des modèles à l'aide de groupes de capture",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '563feea85b1c48d8a7ec3516c3ca59d2',
    name: 'Utiliser des groupes de capture pour rechercher et remplacer',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: '806702d72638455abcbb249b2cf6233c',
    name: 'Supprimer les espaces du début et de la fin',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '5742fa2a9af84b77b7a494f267a3e549',
    premium: true
  },
  {
    id: 'c0bafdd8f1c54e7bbc7635161c9b4b9d',
    name: 'Utiliser un tableau pour stocker une collection de données',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'a4cdc8a873114c33a7e3306464e4850f',
    name:
      "Accéder au contenu d'un tableau à l'aide de la notation entre crochets",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '1a69e52397dc48818b3ac23c890c98e6',
    name: 'Ajouter des éléments à un tableau avec push() et unshift()',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'a0942832a382436ca464c7d09ef1487e',
    name: "Supprimer des éléments d'un tableau avec pop() et shift()",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '5c0531575655418c931b552f7a0299aa',
    name: "Supprimer des éléments à l'aide de splice()",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '1c683467c1ae4a7a83e473f396400131',
    name: "Ajouter des éléments à l'aide de splice()",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'bdbb11a5e93a43df9164c9c34f9b2b19',
    name: "Copier des éléments de tableau à l'aide de slice()",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '6661764877954b42a4d1a13cce340c2b',
    name: "Copier un tableau avec l'opérateur Spread",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'bc015b832adf48f594eb6984b0410ca3',
    name: "Combiner des tableaux avec l'opérateur Spread",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '32c5e8c9f2aa4175a5338218bf87c9de',
    name: "Vérifier la présence d'un élément avec indexOf()",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '4954bf044c0f4261b71c15bb760858f9',
    name: "Itérer sur tous les éléments d'un tableau à l'aide de boucles For",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '6d1fd7a50b024dc1935dca6a68ff7656',
    name: 'Créer des tableaux multidimensionnels complexes',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '072a5663daea4bbda5421bcff0aeee90',
    name: 'Ajouter des paires clé-valeur aux objets JavaScript',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'ea1cc5d4ed604dcd96e366f3259c3acb',
    name: 'Modifier un objet imbriqué dans un objet',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '920eb623804943af8fb0bf48adf0e60e',
    name: 'Accéder aux noms de propriété avec la notation entre crochets',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'cd6d4990b06b46f78cdd89ad31f955e4',
    name: "Utilisez le mot-clé delete pour supprimer les propriétés de l'objet",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '80863174b1844312896b15947fb30a07',
    name: 'Vérifier si un objet a une propriété',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '8e325953035e451a93ee76b9e5a6eaf4',
    name: "Itérer à travers les clés d'un objet avec une instruction for...in",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '05e78b611e0a4d919557ca315cb182f6',
    name: "Générer un tableau de toutes les clés d'objet avec Object.keys()",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: '76197023c84948198ef3110403932378',
    name: 'Modifier un tableau stocké dans un objet',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '8e522b366d3241be8ecef62d6e37e42f',
    premium: true
  },
  {
    id: 'c72517684b2e47fc83a434fb1190a94d',
    name: 'Convertir des Celsius en Fahrenheit',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'ff2638a70f8442578c97959bf0e04894',
    name: 'Inverser une String',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '9ec7e9a909e74bbeab9a0c5052a75f2e',
    name: 'Suite factorielle',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '8983ef68e7674d5dbb32588f8c4b8f10',
    name: 'Trouver le plus long mot dans une String',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'baafa1394d434289b77678a95103d6e1',
    name: 'Retourne les plus grands nombres des Arrays',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'd7881dc2d1704cb8b2de6f3214986c5e',
    name: 'Confirmer la fin',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '35e090a93a294e1b8262c44116666d17',
    name: 'Répéter une chaîne de caractères',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '5b9aa81c63d74f74bcc6bd30d8c90aea',
    name: 'Tronquer une chaîne de caractères',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'd26b7df899b24c6cab2957f212447690',
    name: 'Retrouver les éléments',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'd30b947bea0840fc8061d83be5ac2b66',
    name: 'Quel Boolean',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'a5a98b5af2fe46f2987372fd721eea0b',
    name: 'Majuscule pour chaque mot',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '1304a530bd444f798c90f8e0733f8567',
    name: 'Trancher et découper',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '45b5afff2123405789a7639285868650',
    name: 'Virer les valeurs fausses',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'a60472a1c8c44e0cb92928b4efda9179',
    name: 'Trouver sa place',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'ba686bc77821428f9f3d0d60461b2390',
    name: 'Mutations',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: '6eef51110ef94b57871ecc8fb19352e9',
    name: 'Chunky Monkey',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '2dcbad20bbbc4d12a1c027633bff2ec6',
    premium: true
  },
  {
    id: 'afda1c63473c4a38a212e2fc9e3ed958',
    name: 'Créer un objet JavaScript de base',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'd57f75f4e9394bee8ef6f6f98af0585b',
    name:
      "Utiliser la notation par points pour accéder aux propriétés d'un objet",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '0c8a2e86db8c4851a348f4c271109fdc',
    name: 'Créer une méthode sur un objet',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '78e750cd32c142aaaf5dd63e5d139474',
    name: 'Rendre le code plus réutilisable avec ce mot-clé',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'eb714092c39047ec8b5c2e2faa7f65ba',
    name: 'Définir une fonction constructeur',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'e90638906fee419da91099bec8af3ded',
    name: 'Utiliser un constructeur pour créer des objets',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'e0d1746989bb49d484dd6542648ff9d9',
    name: 'Étendre les constructeurs pour recevoir des arguments',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '5a402b60eb4d4d6b9d7603f85354378d',
    name: "Vérifier le constructeur d'un objet avec instanceof",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '4b93e127a33f4fdc851aeef7fbffd247',
    name: 'Comprendre ses propres propriétés',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '6153808bf078421fa0897894dd1766e9',
    name: 'Utiliser les propriétés du prototype pour réduire le code en double',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '5e6ea4be502843399fd5546b0f8e1603',
    name: 'Itérer sur toutes les propriétés',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'a1942d1b74c44fc6901f1c62289b9e45',
    name: 'Comprendre la propriété du constructeur',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '461202e771024068a47bd27a9e987aa1',
    name: 'Changer le prototype en un nouvel objet',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'f1502ddf16e24380a9b014a5a5c46719',
    name:
      "N'oubliez pas de définir la propriété du constructeur lors du changement de prototype",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '248993bfd00f402bad3935a157aaad5e',
    name: "Comprendre d'où vient le prototype d'un objet",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'af3adead2cd24057897af4ff7c6cacd7',
    name: 'Comprendre la chaîne de prototypes',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'eb081d5a552640f2a2db070379e96deb',
    name: "Utilisez l'héritage pour ne pas vous répéter",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '1f6614178bed4801a6484ecec076277f',
    name: "Hériter des comportements d'un supertype",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '48971d4ed9b84cde89a8d84b519b5c1d',
    name: "Définir le prototype de l'enfant sur une instance du parent",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'c8dea0bacad44d65845c0614a8437640',
    name: 'Réinitialiser une propriété de constructeur héritée',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '84a1aa30cd7f4aec97fac724278a0efc',
    name: "Ajouter des méthodes après l'héritage",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '38856b9edf2743c185815b017f5005f1',
    name: 'Remplacer les méthodes héritées',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '16628a0035ac403a96377f67a8e88a9d',
    name:
      'Utiliser un mixin pour ajouter un comportement commun entre des objets non liés',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '2b50805c28344bfab23b997630e7345b',
    name:
      "Utiliser la fermeture pour protéger les propriétés d'un objet contre les modifications externes",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'a492312351f0430e9e829c457e615a6b',
    name: "Comprendre l'expression de fonction immédiatement invoquée (IIFE)",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: 'a71d942e5fa04bf89bd35927664bb9ca',
    name: 'Utiliser un IIFE pour créer un module',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '50376c3cd57043e7b2f5a96e986e2ea2',
    premium: true
  },
  {
    id: '2801ee88286c47449033c96902f00e5a',
    name: 'En savoir plus sur la programmation fonctionnelle',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'cf5a8934c5d2474895aec018e727c47b',
    name: 'Comprendre la terminologie de la programmation fonctionnelle',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'e4c99bb6811d4bcba5208cb24b3c1b7e',
    name: "Comprendre les dangers de l'utilisation du code impératif",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'e395b6ae705a45a38191b4da18364ac5',
    name:
      'Évitez les mutations et les effets secondaires grâce à la programmation fonctionnelle',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '9a01c33cac16470fb8b45cc8d30053d7',
    name:
      'Passer des arguments pour éviter la dépendance externe dans une fonction',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '25fb9d763c6f41faa7b8b7f3d10249e2',
    name: 'Refactoriser les variables globales hors des fonctions',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '38a59459127749f386036aca39823b8f',
    name: "Utiliser la méthode map pour extraire des données d'un tableau",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '9e01bd920ff845af8694679eddaaab05',
    name: 'Implémenter la carte sur un prototype',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'cfd54a4fbcf54d4896a7062192b266d0',
    name: "Utiliser la méthode filter pour extraire les données d'un tableau",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '2273dc828d6d4a74a922ee8803ebeeaa',
    name: 'Implémenter la méthode filter sur un prototype',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '304e41457a6c4e2cbdf77c1e9a671be8',
    name: "Renvoyer une partie d'un tableau à l'aide de la méthode slice",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'f24909b0d4984aa98cd6df98fe469b7e',
    name:
      "Supprimer des éléments d'un tableau en utilisant une tranche au lieu de l'épissure",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '0d3ee4f39bc54a1a835dc7f44422e3b6',
    name: "Combiner deux tableaux à l'aide de la méthode concat",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '8c019e827cbc465c94d245ab56230835',
    name:
      "Ajouter des éléments à la fin d'un tableau en utilisant concat au lieu de push",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '26cd9d39e80648b886c9a55d19387d21',
    name: 'Utiliser la méthode de réduction pour analyser les données',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'dcd57f15b3a643f6bb36bfd265dc2880',
    name:
      "Utiliser des fonctions d'ordre supérieur mapper, filtrer ou réduire pour résoudre un problème complexe",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'c3f651e81cc64906849a8322abbd3a56',
    name:
      "Trier un tableau par ordre alphabétique à l'aide de la méthode de tri",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '71619c34e3784eb7ad186b1e41d0da79',
    name: "Renvoyer un tableau trié sans modifier le tableau d'origine",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '457e7975171943f1aa0167badcedcbcf',
    name: "Diviser une chaîne en un tableau à l'aide de la méthode split",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '4ac2866e002446d2b81d999acddcc590',
    name: "Combiner un tableau dans une chaîne à l'aide de la méthode join",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '23e48a50ba0f47c7a920ff513526731a',
    name:
      'Appliquer la programmation fonctionnelle pour convertir des chaînes en URL Slugs',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '631b3d1ec30748ac9aed6018b4a59ffe',
    name:
      "Utilisez la méthode every pour vérifier que chaque élément d'un tableau répond à un critère",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: 'e5eabe7784b840069fc75755f00401b2',
    name:
      "Utilisez la méthode some pour vérifier que tous les éléments d'un tableau répondent à un critère",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '628c7f5f080a41cab08fa50f7ccca236',
    name: 'Introduction au curry et application partielle',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '96c3417cc35a424ea5d3bbf1e0510c2d',
    premium: true
  },
  {
    id: '04c9574572c74ff1b311954e77820ce2',
    name: "Trouver la somme d'un rang",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },
  {
    id: 'ac04b26e687948e3a034ed70f06e483c',
    name: 'Trouver la différence entre deux tableaux',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },
  {
    id: 'f2b32a6719cb4866af83cf2d50a651b4',
    name: 'Chercher, trouver et... supprimer',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: 'd1714b39bac241eebf413ac08e935e34',
    name: 'Filtrer avec un objet',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: 'ba36e765f8334cee943859f63a17fefa',
    name: 'Spinal Case',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '9a5ef098b7064fe4b11939f49e57a886',
    name: 'Convertir en argot',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '8d2d4682416a4928b83b549046164742',
    name: 'Chercher et remplacer',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '89f64844a3eb490391d9ee57e3e2f1aa',
    name: 'Former des paires',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '493f1b8be09841568c386e09611cfdf8',
    name: 'La lettre manquante',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '8bd0287652c64042a4f1673e3ba20add',
    name: 'Unir et trier',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: 'a8d86eb391f24547b0cfb22ef3a9d960',
    name: 'Convertir des entitées HTML',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '320ff222ea8f4a7cab2af1ce1a585901',
    name: 'Somme de tous les nombres paires de la suite de Fibonacci',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '272dbe61dd9141469904462da4eec351',
    name: 'Somme des nombres premiers',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: 'e8efa7e8ed1744bd8251799066086fa3',
    name: 'Plus petit commun multiple',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: 'fdd97d2a89d64dd3992747d940b47af8',
    name: "Filtrer jusqu'à la vérité",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '456877423fa14d20b84231463d89e32c',
    name: 'Applatir un tableau',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },
  {
    id: '72ae64aa57ab45089dd64086d9c6a445',
    name: 'Agents binaires',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '7fe52ddcfac14a1586855f67e44d16fc',
    name: 'Tout doit être vrai',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '3fc997c3736b4022a0192a548c45bb76',
    name: 'Arguments optionnels',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },

  {
    id: '8a4d27b53738429dbbaeb005e074b0ae',
    name: "Le constructeur d'objet",
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },
  {
    id: 'ed7ad8bbe4bd4a96ac8e2593ec679395',
    name: 'Modifier un tableau',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '88a949f8289f4a63a38295e3a9608a1d',
    premium: true
  },
  {
    id: '02ffef8f537a4f128cbd0f3780ae8eef',
    name: 'Introduction à la certification JS',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '166d7c456a18468dbc19459fc1e8c505',
    premium: true,
    video: 'YnK-qV0hq2o'
  },
  {
    id: 'e295888c5dbd4e1b9aaf1b3909ac9b12',
    name: 'Vérifier les palindromes',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '166d7c456a18468dbc19459fc1e8c505',
    premium: true,
    video: 'qLj3aSAKugc',
    codesandbox: 'palindrome-syt34'
  },
  {
    id: '6226d60b971d4e83976b42c181c94c70',
    name: 'Convertir en nombre Romain',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '166d7c456a18468dbc19459fc1e8c505',
    premium: true,
    video: 'BqOokvaz0lw',
    codesandbox: 'conversion-romaine-hmw7b'
  },
  {
    id: 'f93cd0af285c444c8322615013a100ab',
    name: 'Chiffrement en ROT13',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '166d7c456a18468dbc19459fc1e8c505',
    premium: true,
    video: 'CUUpZqfv-C4',
    codesandbox: 'rot-13-nr3ee'
  },
  {
    id: 'ce39434557af4402b14477e387123c65',
    name: 'Valider des numéros de téléphone',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '166d7c456a18468dbc19459fc1e8c505',
    premium: true,
    video: '3ZPMkncX6P4',
    codesandbox: 'validateur-de-telephone-9z1um'
  },
  {
    id: 'b10b047da0f242fcbca887e136efa8b5',
    name: 'Coder une caisse enregistreuse',
    certification: '309f6c2d3b764fc98831da3ba96615ae',
    chapter: '166d7c456a18468dbc19459fc1e8c505',
    premium: true,
    video: '-mMeGFCBC-k',
    codesandbox: 'caisse-enregistreuse-83n5s'
  },
  /*

    FRONT-END
    **********************************************

  */
  // DOM
  {
    id: '4aa2ab5b1d4e43619beecb432e87cb71',
    name: 'Les balises script',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co08040ab824b427c2bb5a696'
  },
  {
    id: 'a374405ab1fc4e068222747cb1616828',
    name: 'Lier un fichier JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coa074585b75c00d11389dde6'
  },
  {
    id: 'edf9237b88234047b690b24e2aa91b93',
    name: 'Le DOM : Document Object Model',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co2ea4c7ea2c445e553ca0f07'
  },
  {
    id: 'c39aa7a174094f8ba95a36600aa33362',
    name: 'Trouver un élément avec getElementsByTagName()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coc014661b92938d9bdddde65'
  },
  {
    id: 'db2987c538ff4e34ad86526e7fc79f26',
    name: 'Lister plusieurs éléments avec getElementsByTagName()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coc3847a1a0497a9034ce859b'
  },
  {
    id: '5e255e1488f24f3fb713d7e7f5155847',
    name: 'Trouver un élément avec getElementById()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co95f469cb9043d35da0aa6e4'
  },
  {
    id: '0a603e3f2cfe43edace64eecf41391e2',
    name: 'Déplacer un élément avec insertBefore()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co67d4a2e8aa97e09d969b7ca'
  },
  {
    id: 'b629bbd62d034fcc96a9d6ab934c2bc5',
    name: 'Déplacer un élément à la fin du body avec appendChild()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co5bd4b42a46d0306a2380977'
  },
  {
    id: 'bca2a123dbd748cda33eba5530eee476',
    name: "Déplacer un élément à la fin d'un autre élément avec appendChild()",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co1104611826e3b6469a6b861'
  },
  {
    id: '73077b6c8c02422f8980d6b2c53c4f17',
    name: 'Trouver des éléments dans un autre élément',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'cof6c4e3b83398ec5d53ac8f5'
  },
  {
    id: '60b0a054683c42e7886a4e26d6d75b2f',
    name: 'Déplacer plusieurs éléments',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co4cf4d7c8e48d6c87fba7708'
  },
  {
    id: '7e3d83516e1c4d8989d7b9ed32e1c93c',
    name: 'Supprimer un élément',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coebc4dc698464af290fe193c'
  },
  {
    id: '071796f623dd49b2846928b94dd67c52',
    name: 'Créer un élément de texte createTextNode()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co3164681bcb445cf0bb73d64'
  },
  {
    id: '0bb7ed1e5845480c843351e681f58559',
    name: 'Insérer du texte avec appendChild()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co25344be8015addb944bc558'
  },
  {
    id: '9d495fac8be745498b337786099a7101',
    name: 'Remplacer du texte avec replaceChild()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co2f5439fa4d6c6c00584ea08'
  },
  {
    id: 'd2786a9b344a401e8124fb8801772b95',
    name: "Créer n'importe quel type d'élément avec createElement()",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co387466cb1a53af1ece79aa1'
  },
  {
    id: 'c1071d2036724052a87e6de1e314400e',
    name: 'Modifier le texte avec innerHTML',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co99f440195e7061b2cb48608'
  },
  {
    id: 'ef2de2d9015f490db5d89e7bebf9ab90',
    name: 'Créer du HTML avec innerHTML',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co3cc4936b8a3cdfd2fb34dcd'
  },
  {
    id: '68feb91355d94a2bbc7c50788c0d8911',
    name: 'Créer un mini-chat',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coa5747a0b472f10363129123'
  },
  {
    id: 'ebc20abb67904e549144c0f816ece515',
    name: "Récupérer la valeur d'un attribut",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co332419fb013d6e70521b6a1'
  },
  {
    id: '5a045b87ffca4041a953aefed7db5a5b',
    name: "Modifier la valeur d'un attribut",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'cod0f439a9ed457feb0edfca1'
  },
  {
    id: '9e936e7f889b4fb0b1ed688e3b117613',
    name: 'Transformer une HTMLCollection en Array',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co43e4a2992135a5e5d69a611'
  },
  {
    id: 'dd45d61e5b924f1592aef027276ed270',
    name: 'Modifier plusieurs éléments avec forEach',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co8c44d9eb7c2b71cf57c447e'
  },
  {
    id: 'ffb26ee9819b41d483f1e884ab4bce1b',
    name: 'Trouver la largeur de élément avec clientWidth',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co30247d5b89d4daabd804bc2'
  },
  {
    id: 'bfbb4286dc0346b4b4f5f39f84a84cae',
    name: 'Trouver la hauteur de élément avec clientHeight',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'cobe14004bf3ab016cb3e51fb'
  },
  {
    id: 'f91537bdd44d462a9158b40e3cdc5bfc',
    name: 'Trouver la largeur de élément avec offsetWidth',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co1a54aa6af617ec6407d92fb'
  },
  {
    id: '18dc5d42dea444c3a12887fa4c0da0a0',
    name: 'Trouver la hauteur de élément avec offsetHeight',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co63c4e2397302c26bfea7bf6'
  },
  {
    id: '7fe4a67696ac4c8d862972fed69511fc',
    name: 'Modifier le style en JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coa7a489fab53112a7e2dc2f6'
  },
  {
    id: '28ae7fe24c6247428201edf5aa965955',
    name: 'Modifier le style en JavaScript 2',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co23f4b068928b1557b715fdc'
  },
  {
    id: '359161ccfb124799abfd8bc99e672419',
    name: 'Sélectionner un élément avec querySelector()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'cob2048a09d63af8528aec552'
  },
  {
    id: 'c7e408e5bfbd48ab85935892ba89fef9',
    name: 'Sélectionner plusieurs éléments avec querySelectorAll()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coef84a16acaf9651be127202'
  },
  {
    id: '9b523dd995154def949170c7cb30c892',
    name: 'Boucler sur une NodeList et créer de magnifiques couleurs',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co1c84a78a0062b9018ba1c53'
  },
  {
    id: 'bf1c169f257b497d97efaff5bc23224d',
    name: 'Lister les classes CSS en JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co717472e8a4f061313ebfa74'
  },
  {
    id: '63bd01d2537740f4b46f67938b0a47d9',
    name: 'Ajouter une classe CSS en JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co8ad43ea986a18145193a1e4'
  },
  {
    id: '7253b891a12245f3b66e134fd71088ff',
    name: 'Supprimer une classe CSS en JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co9154874a8ff7bbc9cd36782'
  },
  {
    id: 'a5aa35e124ea425589f428f7563ffb58',
    name: 'Toggle une classe CSS en JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co2cb4447b493e1e67cdf3fba'
  },
  {
    id: '1e62832bdaef488194990848f793a620',
    name: "Trouver la position d'un élément par raport au haut de la page",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'co1f1418db71c899a90aeb095'
  },
  {
    id: 'c66818111b3e49639bdb66b0c7b59ec7',
    name: 'Scroller en JavaScript',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
    premium: false,
    scrimba: 'coed64043a3a0a30f9d9fe844'
  },
  // ^^^^^^^^^ Vidéos enregistrées ^^^^^^^^^
  // {
  //   id: 'bd780d89619a461b84dcce45c132de48',
  //   name: `Exercice : Créer un écran de veille Windows 95`,
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
  //   premium: false,
  //   scrimba: 'co0994158829bf89b30ee1351'
  // },
  // {
  //   id: 'a1eb423f55964202a7e164fedad2588f',
  //   name: `Créer un écran de veille Windows 95 - 001`,
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   chapter: 'f2752e6fb398414d9a7b2a5113afd14c',
  //   premium: false,
  //   scrimba: 'cod09411993e12e9209a5a57a'
  // },
  /*

    END
    **********************************************

  */
  {
    id: '626fb8952fc44db5b5ccc3d416b9457f',
    name: 'Présentation du chapitre React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    video: 'rFfvWwBYtEI'
  },
  {
    id: '003f785ea72c40829ced8ec1df89a9ec',
    name: 'Créer un élément JSX',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'peaceful-smoke-jtegep'
  },
  {
    id: '7cc1da0b0d9642c788d1a29d98081146',
    name: 'Utiliser des expressions dans JSX',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'flamboyant-smoke-jkixk6'
  },
  {
    id: '1f0da5e6ed334b37b7a7645e05530e87',
    name: 'Utiliser des fonctions dans les expressions',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'wonderful-haze-rwfrpd'
  },
  {
    id: 'c8edc578c5b74c30ad7824b0eebfed5c',
    name: 'Jouer avec les conditions en JSX',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'admiring-wood-bjein8'
  },
  {
    id: 'd51ab63d8b3a4c54a4f23c4a49601664',
    name: 'Les attributs JSX en camelCase',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'wizardly-https-7x3epv'
  },
  {
    id: '688308f253f744108136170568eab4ad',
    name: 'L\'attribut "className" en JSX',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'crazy-tu-0w5d4g'
  },
  {
    id: 'd85c09e6b19241bf817ea82de72716cf',
    name: 'Importer un fichier CSS dans React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '9b1a1e7517674fb1b3830171c463c12d',
    name: 'Utiliser une expression dans un attribut',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'romantic-surf-1u3d80'
  },
  {
    id: 'f64ae08e37e743b8b7344e34a3d36ceb',
    name: 'Fermer les balises vides',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'b779e5eb464b43a4bb50e23a23b4ea7b',
    name: 'Éléments JSX imbriqués',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'stupefied-night-47ewug'
  },
  {
    id: '6d7c4f61a84349f3a02923b1b8628039',
    name: 'Les Fragments JSX',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'd4adc9e1ef4545cd9335982c7f02bf68',
    name: 'Rendre un élément',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'd11cb0c6750945cfb535c943e38891ad',
    name: 'Mettre à jour le rendu',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'b58ccd92a30b45aa931f15f1b32c1d51',
    name: 'React ne met à jour que le strict nécessaire',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    video: 'V1VDHhpZuBQ'
  },
  {
    id: 'a8b0b1e87c5c468db82dd6bab42a40bf',
    name: 'Créer un composant React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'wizardly-lucy-6n1w7o'
  },
  {
    id: '7a8001f961c44a62b2988776469e1ac1',
    name: 'Ajouter une props au composant',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'epic-shockley-d561u9'
  },
  {
    id: '6a496dfe581c40a8a5bd72f8c95fc73f',
    name: 'Destructurer les props pour un accès plus facile',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'epic-shockley-d561u9' // même que précédent c'est normal
  },
  {
    id: '406b3b6d9fac4de48913d75a71f1dfc0',
    name: "Différencier un composant d'une balise",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'bf79050868b04592824183a3979f9c85',
    name: 'Composer avec des composants',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true,
    codesandbox: 'inspiring-moser-5uoqgd'
  },
  {
    id: '2834b169dbef45ca8a9f8e014469785c',
    name: 'Diviser tes composants',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '107083bc061e429396d47e2ebcc3ae60',
    name: 'Les props sont en lecture seule',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '7752ff5bfe6f44eeaa0e95cb8827dbaf',
    name: 'State : Introduction',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'a249e41f0fe5405c96c9b1f0d505d8b8',
    name: "State : Isoler l'apparence",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'f50dcf3dc92a4598b15548d93f115247',
    name: 'State : Utiliser useState',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '7ded7c2f05f3448aaa640e0f9557cd11',
    name: 'State : Utiliser useEffect',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'f1ef3f564d254cd9aad3ca3f54287436',
    name: 'State : Mettre à jour le state',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'd50a5457ade84705b21097d84b861181',
    name: 'State : useEffect fonction de nettoyage',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'ef0d14e0795e4e0f80a0563078cde081',
    name: 'State : initialiser avec une props',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '2ab5092ff8874352b46f7f353819c03f',
    name: "State : ne lire le useEffect qu'au montage",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'cb83d826e1cf4c559c375a4fcc128e0b',
    name: 'State : choisir quand activer useEffect',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '3183d0d9980b4ccabd2a115fed315dbf',
    name: 'State : anti pattern',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '8730cf33b9cf4d0bb44d090fee74277f',
    name: 'State : réutiliser notre composant',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '97659bfbb4654af9a7cda85183d32c9d',
    name: 'Créer un événement avec React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '3dd7c3c37e5043cbbf4dc1cac0f6387a',
    name: 'Utiliser les fonctions "handle"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'a90bc8bc069e42418c49cec2ad9439e4',
    name: 'Comprendre event.preventDefault()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'ec49884c9aed4347af44b56d4f396898',
    name: "Passer un argument au gestionnaire d'événements",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '909b16e454224bd8ac69a81a9f8862c8',
    name: 'Utiliser dangerouslySetInnerHTML()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '0b691e8421804a8ab32264eef85a3c67',
    name: 'Faire du Markdown avec marked.js',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  // {
  //   id: '1ccdeff23451496bb240a22b901df60f',
  //   name: 'Premier Projet React',
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   chapter: '5f5ee00339f3412abf95f8eb891934e2',
  //   premium: true,
  //    },
  {
    id: 'fd259d53510c4c2a9fabf40c679219d1',
    name: 'Affichage conditionnel avec "if"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'b4ee38b7c056414aa6f6b092230e8547',
    name: 'Affichage conditionnel en fonction du State',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '2d22881f7c7b4f0f92cc1f5b08fa11cc',
    name: "Affichage conditionnel avec l'opérateur &&",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '26c1838360a34ba9b97def444c25c3a5',
    name: "Affichage conditionnel avec l'opérateur ternaire",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '1f374b7eb61540f8892d9fabbd3be9ed',
    name: 'Masquer un composant avec "null"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'a9cd77f82e844e58b02df4c3e76a25f5',
    name: 'Rappel de map()',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '77a27818bbbe4fec8e40fde3067443b9',
    name: 'Créer des listes avec React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '9482e6b9d2ef432dab3dc65c34318146',
    name: 'Les clefs dans les listes React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '31fcbbe06cfd4a74924b037c541cd211',
    name: 'Les clefs à ne pas utiliser',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'c83e58970e3a480b8b0487d717bfb945',
    name: 'Une erreur fréquente avec les clefs',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '3851225a1b1b4da4be5e278d223f6b92',
    name: 'Les clefs ne doivent être unique que dans leur liste',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'b719fa1dbc004c86a5ba9e53c95f6215',
    name: 'Comprendre les composants contrôlés',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '848f5436956e421985b5b2b9361e0b2f',
    name: 'Lier le State à un input',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'a451b06c86ef42afb1423c5def1d48b9',
    name: "Contrôler le state et la valeur de l'input via une fonction",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '478a768731e14a9b90899c232c7bbdbb',
    name: 'La balise textarea',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '8741b50440ef433888450d524bd597bb',
    name: 'Utiliser "name" pour gérer plusieurs champs',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'cb606f99f05541439a8f049dd0dd03d5',
    name: 'Passer des fonctions en props',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'afa4a11ac2ba409ca9664d92fa140c91',
    name: 'Où mettre le State ?',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: '9d5faa5a5a6c422cb1e9420b1ee9197c',
    name: 'Une props spéciale : children',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  {
    id: 'c3fa3a27c44a45d788845e67b93abb84',
    name: 'Créer des composants paramétrables',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '5f5ee00339f3412abf95f8eb891934e2',
    premium: true
  },
  // Fetch API
  {
    id: '9c6233d64ae74c84b9b920c174b53542',
    name: 'Présentation du projet Fetch API',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  {
    id: '3445cc6a7ac34a8fae4f200640d048ec',
    name: 'Utiliser fetch() pour récupérer des données',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  {
    id: '06835c11b0744a56b4ac3f972fd07bf6',
    name: 'Utiliser json() pour utiliser les données',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  {
    id: '78bddc37cf114d19ac7d3288d2d89a08',
    name: 'Stocker les données dans le State',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  {
    id: 'b2ce83d96aee482db73f76b36f48a7e6',
    name: 'Afficher les données',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  {
    id: '5ee8637017484600a63931667adae821',
    name: 'Customiser la requête',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  {
    id: '0315a0b42a2f4890b89e139c2b48ca57',
    name: 'Utiliser les événements pour changer de données',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b1070008b30e471ebb4277ce2ddbe14a',
    premium: true
  },
  // A PUSHER
  // Styled Components
  {
    id: '99231f63a4484e4d8012a3ccf3b56553',
    name: 'Présentation de styled-components',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '552c967e41224fcd97e16eebe9313ced',
    name: 'Créer un composant avec styled-components',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: 'bb2f4fde45324a15858f4ba3bef29292',
    name: 'Utiliser les props avec styled-components',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: 'dc3c11e1e89246c38bcb18687779e28c',
    name: 'Étendre le style',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '251266069e1a4b98a578d4d7c9dd8d68',
    name: 'Changer la balise du composant avec "as"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '6eb9c69607d54f40accbeb30d13b66ed',
    name: 'Changer le composant du composant avec "as"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: 'd73b990f273b441992138c37a2704a22',
    name: 'Les props sont passées automatiquement',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: 'bd23fc1177fa4a50b7f9b701132c985d',
    name: 'Définir le style au bon endroit',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: 'de81d39976e64614a8540db9e3928be9',
    name: 'Sélecteur avec "&"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '014890200b1940919c9d0950aa05be02',
    name: 'Ajouter des props avec ".attrs"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: 'adf9d046303445ecac0f22f98d2b3fbf',
    name: 'Cas pratique',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '1bb01be0cffa42ffba9814117f2163f5',
    name: 'Sélecteur brut',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '45421ff9b96041e699bf1a9646220fe7',
    name: 'Utiliser les valeurs par défaut avec ".attrs"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  {
    id: '9ad008165bd644edb11ee26baf16dbe8',
    name: 'Créer des animations avec "keyframes"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'a74c6d6e0c63408ca1bcc2090774fb60',
    premium: true
  },
  // React Router
  {
    id: '8fe4a50e23ff438fb03eb7dc0f98e8d1',
    name: 'Présentation de React Router',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-5hn4m2',
    vimeo: '720249583'
  },
  {
    id: '313e6efd699b490dac1c42e026d036da',
    name: 'Utiliser Browser Router',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-002-y5db3e',
    vimeo: '720249640'
  },
  {
    id: '3f4feb7e1bdc4907b03477905e46c160',
    name: 'Ajouter des liens avec "Link"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-003-f4ovfo',
    vimeo: '720249688'
  },
  {
    id: '3cfc16973a1046328c0bf7c135e8e05a',
    name: 'Ajouter des routes',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-004-ffspym',
    vimeo: '720249733'
  },
  {
    id: '5a2ec7179b3548fc824d255e64c79785',
    name: 'Imbriquer des routes',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-005-s8xddg',
    vimeo: '720249763'
  },
  {
    id: '7545be203947451da2a43f029af51d5f',
    name: 'Lister des liens',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-006-jzk9jg',
    vimeo: '720249819'
  },
  {
    id: 'eb228cf7ef9d4aa1b66af6598bcb667a',
    name: 'Ajouter une page 404',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-007-1z4bux',
    vimeo: '720249850'
  },
  {
    id: 'b50fc139e59c4b5fb716583c81cbeefb',
    name: "Lire les paramètres d'URL",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-008-kmtsqz',
    vimeo: '720249886'
  },
  {
    id: 'f121202216d5446ea4a3761fe64bbfe6',
    name: 'Une route par défaut avec "index"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-009-z7c60d',
    vimeo: '720249959'
  },
  {
    id: '2cccb53b046b4942b04804c307a795b4',
    name: 'Trouver les liens actifs avec "NavLink"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-010-8ng1nd',
    vimeo: '720249988'
  },
  {
    id: '7807f2960b3e4b41931b1ca14e2003a7',
    name: 'Utiliser les paramètres de recherches',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-011-ih5cco',
    vimeo: '871319505'
  },
  {
    id: '774bc31514634b9f8d59bbc9b07bd8f9',
    name: 'Customiser les liens avec "QueryNavLink"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-012-rhlgzm',
    vimeo: '720302092'
  },
  {
    id: 'b467c503e92848eb999433739797ee16',
    name: 'Naviguer directement depuis le code',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'b3a1d79295f34cbeaebbfc2728e1177f',
    premium: true,
    codesandbox: 'react-router-hardcoders-013-zyv1ce',
    vimeo: '720302156'
  },
  // React Niveau 2
  {
    id: '5dcbb10f27d64b05968508e1e7aa7897',
    name: 'Les deux types de variables',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810113951'
  },
  {
    id: 'be5c7e290235463f921f0c9f9a9815ed',
    name: 'Les rérérences et les objets',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810113984'
  },
  {
    id: '4afd4ad783344c7bb2799e750924ee0a',
    name: 'Copier un objet en mode shallow',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114005'
  },
  {
    id: 'da2e351209ef459cbbe3dc3356a9a104',
    name: 'Copier un objet en mode deep',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114035'
  },
  {
    id: '41d7f697067f4c10a5c1445517a6b54e',
    name: 'Les références et les tableaux',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114173'
  },
  {
    id: 'e218ef6aeab74cd1adb67fcd8bedf4d9',
    name: 'Copier un tableau en mode shallow',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114203'
  },
  {
    id: '3c5f61435a5f49e1b5899d3811d653d1',
    name: 'Copier un tableau en mode deep',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114221'
  },
  {
    id: '19d86ce5ffdb41cbb43fba941c604a51',
    name: 'Rappel sur la mise à jour des composants React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114245'
  },
  {
    id: '7e10b6facc894c4d8e951870d1465d55',
    name: "Démo d'une mise à jour de state",
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114266'
  },
  {
    id: 'dd80914b3d624d008d46590d7deaf533',
    name: 'Utiliser React Memo',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'ee2266a6543941dbb3c910b046faa1c7',
    premium: true,
    vimeo: '810114293'
  },
  // React Context
  {
    id: '9e043465d54d407b9a601bc2df6d501f',
    name: 'Présentation de React Context',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'df0e88e5bac2452c9cc9c670f0ffcd80',
    premium: true
  },
  {
    id: '9de826d6ccbe47adb40d0d0a352c5f78',
    name: 'Créer un Context',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'df0e88e5bac2452c9cc9c670f0ffcd80',
    premium: true
  },
  {
    id: '3457b1d789d849b58f25e186d24ded95',
    name: 'Créer un Provider',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'df0e88e5bac2452c9cc9c670f0ffcd80',
    premium: true
  },
  {
    id: 'd2e8d7e048164e0fbbc6ade4a853f5ee',
    name: 'Le hook "useContext"',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'df0e88e5bac2452c9cc9c670f0ffcd80',
    premium: true
  },
  {
    id: 'cc98e0995b8745408c0c0c75633a1640',
    name: 'Et Redux ?',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: 'df0e88e5bac2452c9cc9c670f0ffcd80',
    premium: true
  },
  // Certification Front end
  {
    id: '76dae5158e5d412e9fbfa8236b610be9',
    name: 'Comment valider les projets Front-End',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '0ebd3d7a874d4c18bdb1f27609711930',
    premium: true,
    vimeo: '721054693'
  },
  {
    id: '0b816902fef64c53b82a6c3be41f96a4',
    name: 'Créer un éditeur de markdown',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '0ebd3d7a874d4c18bdb1f27609711930',
    premium: true,
    codesandbox: 'markdown-2dpor5',
    vimeo: '721054559'
  },
  {
    id: '000a688f7f044354bdae7b54c3d7e8a8',
    name: 'Créer un minuteur Pomodoro',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '0ebd3d7a874d4c18bdb1f27609711930',
    premium: true,
    codesandbox: 'pomodoro-py8t45',
    vimeo: '721054625'
  },
  {
    id: '5e5bdb7ef83940119c3b489961d4a16d',
    name: 'Détecter la brasserie la plus proche',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '0ebd3d7a874d4c18bdb1f27609711930',
    premium: true,
    codesandbox: 'brasserie-993r3v',
    vimeo: '721054645'
  },
  {
    id: 'aebaa4bb31a34ece8ba066364a2f1dca',
    name: 'Créer un panier de e-commerce',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '0ebd3d7a874d4c18bdb1f27609711930',
    premium: true,
    codesandbox: 'e-commerce-8iwche',
    vimeo: '721054668'
  },
  {
    id: '669e4d8c017e44d580fca2e2f5f50bca',
    name: 'Créer un Pokédex',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    chapter: '0ebd3d7a874d4c18bdb1f27609711930',
    premium: true,
    codesandbox: 'pokedex-o5g3f5',
    vimeo: '721054600'
  },
  // BACKEND
  // NODE.JS
  {
    id: '4dce2051224a4385b3ba9faf671f7be4',
    name: "C'est quoi Node.js ?",
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    vimeo: '755136622'
  },
  {
    id: 'f0623d818fb140749f58a24a9cd745f4',
    name: 'Pourquoi utiliser Node.js',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    vimeo: '748254663'
  },
  {
    id: '62f148a1cea147de8d3a0c2e976bea64',
    name: 'Installer Node.js',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    vimeo: '748254670'
  },
  {
    id: '9620e08e347d47b297c0bc88e7f12deb',
    name: 'Créer ton premier script',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'trusting-khorana-xp394i',
    vimeo: '748254680'
  },
  {
    id: '021ae25325df4fd8abab942d56de607a',
    name: 'NPM',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    vimeo: '748254687'
  },
  {
    id: '275d518d6845454eb8a558a6611cda70',
    name: 'Installer un package',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'gifted-sun-0tkd59',
    vimeo: '748254692'
  },
  {
    id: '5f804d2241ba4ff6b7ddc2b66e102e74',
    name: 'Importer un module',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'amazing-ishizaka-095hxx',
    vimeo: '748254702'
  },
  {
    id: '454a38861e364237b291604d4d01cfa8',
    name: 'Utiliser nodemon',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'ough-pond-t28d9s',
    vimeo: '748254707'
  },
  {
    id: '4f193253b8624a6f9b2b6ee129bd48ab',
    name: 'Comprendre le module Path',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'friendly-wu-jjwvdv',
    vimeo: '748254716'
  },
  {
    id: 'e97d7a28d3b046a28fc0e9059d11836a',
    name: 'Path et ESM',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'sweet-worker-r1izi4',
    vimeo: '748254729'
  },
  {
    id: 'a49a098b9f474da683252fc27c1b08b3',
    name: 'Utiliser les fichiers système avec FS',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'b4257ff56b3244b9bd70c87803f8b3d2',
    premium: true,
    codesandbox: 'runtime-https-lmy931',
    vimeo: '748254741'
  },
  // Projet 1 en ligne de commande
  // {
  //   id: '1b60db7fdccb472993ee93aff16b1bd0',
  //   name: 'Présentation du projet Terminal',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254750'
  // },
  // {
  //   id: 'b6bcf2ed5f3143d293bbd454bb78d863',
  //   name: 'Utiliser Yargs',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254758'
  // },
  // {
  //   id: '3b9a1f7560dd4d7486112fba395971b4',
  //   name: 'Ajouter des paramètres',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254764'
  // },
  // {
  //   id: '14c5275da1fa401690e9a2e29d2bf2d4',
  //   name: 'Utiliser le package Chalk',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254772'
  // },
  // {
  //   id: 'a4bce4377d314126b1b3e7c8394a4a1e',
  //   name: 'Créer des liens',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254780'
  // },
  // {
  //   id: 'd2277b1c40f04bfab12355584303eae7',
  //   name: 'Utiliser le package Clipboardy',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254789'
  // },
  // {
  //   id: 'ddf5e87573c94bd9b4c6e57f0510a49a',
  //   name: 'Générer le lien final',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254799'
  // },
  // {
  //   id: '846347231b944ff8bd30820ccf03166e',
  //   name: 'Utiliser le package Validator',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254810'
  // },
  // {
  //   id: '85ab7d8bea8c42d5a69b552dce51f9f6',
  //   name: 'Lister tous les liens',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '4f04b6c86d3343469e98e98499110138',
  //   premium: true,
  //   codesandbox: 'weathered-snow-lz699g',
  //   vimeo: '748254818'
  // },
  // NPM
  {
    id: '0d0c276521944538aa0ae0c85706b8e9',
    name: 'Introduction à NPM',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556105'
  },
  {
    id: '5d194aa4873c41d09f96e3a7eea92d2e',
    name: 'Créer un projet NPM',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556040'
  },
  {
    id: '7656594830944ba28b025314c5216423',
    name: 'Comprendre le fichier package.json',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556175'
  },
  {
    id: '24f0ba2bafb44e74aedfbf333389a967',
    name: 'Les propriétés description, keywords et licence',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556053'
  },
  {
    id: '01bbecee64354dbe969b9719510d5335',
    name: 'Installer un package avec NPM',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556084'
  },
  {
    id: '67b18371c41d420dafa317788faee8ac',
    name: 'Comprendre le système de version',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556245'
  },
  {
    id: '4387cf52199640838e7a76f252209ce7',
    name: 'Mettre à jour un package',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556217'
  },
  {
    id: '9c0ff2a9b7334417b0d4efc66f9cdc16',
    name: 'Installer et utiliser des packages globaux',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556191'
  },
  {
    id: '35fc02a4c7e04233b91226248d5e9126',
    name: 'Ajouter des dépendances de développement',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556068'
  },
  {
    id: 'ba8a064a7f614499a0a5426656722e44',
    name: 'Ajouter des scripts pour faciliter les tâches répétitives',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556203'
  },
  {
    id: '0508657969b74816996ec9e69c9f3fdf',
    name: 'Exemple avec le package nodemon',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556146'
  },
  {
    id: '41803396e60a4242a6e909cd95664cff',
    name: 'Lister les packages installés',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: 'bcb7b2ce872441d3b4ff9cb0abf3d21a',
    premium: true,
    vimeo: '739556124'
  },
  // // Express
  {
    id: '9ed10d2450564e1aa4d987dac65e99cf',
    name: 'Présentation du projet Express',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    vimeo: '739556552'
  },
  {
    id: '03a093ee7ff14c34bc857000d90f1eb6',
    name: "Initialisation d'un projet Express",
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    vimeo: '739556566'
  },
  {
    id: '1c4b294a76824768b57666833bbe9cb7',
    name: 'Comprendre Express',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'competent-stallman-m26ihr',
    vimeo: '739556578'
  },
  {
    id: 'd4b9039aba6941cbafdd29c8f2fe5609',
    name: 'Le dossier public',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'quirky-einstein-9y9hol',
    vimeo: '739556588'
  },
  {
    id: 'a190165aa42545df93dccbf2887e2526',
    name: 'Installer nodemon',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'optimistic-torvalds-5v1ys6',
    vimeo: '739556596'
  },
  {
    id: '2417a6c7b840456fb0e9a32526479dee',
    name: 'Rendre des pages avec Handlebars',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'dazzling-agnesi-qqe3xj',
    vimeo: '755136676'
  },
  {
    id: 'f11196af4b224a7e8252fff369ba4432',
    name: 'Un autre moteur de rendu : Pug',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    vimeo: '739556616'
  },
  {
    id: '9af8ab01efb64cbda0e179333cb00fbe',
    name: 'Comment structurer un projet Node et Express',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    vimeo: '739556627'
  },
  {
    id: '34a9ebc4e8e8444886ad58f7d8ad45c1',
    name: 'Utiliser un layout',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'dazzling-agnesi-qqe3xj',
    vimeo: '739556633'
  },
  {
    id: '47ff5dda4c7f4034b176dc5cd471f936',
    name: 'Utiliser des variables',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'dreamy-haslett-xf310l',
    vimeo: '739556643'
  },
  {
    id: '5d8bbd3268c34d119b95ff7ea0b6a33f',
    name: 'Utiliser des partials',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'jolly-blackwell-vy7jxy',
    vimeo: '739556654'
  },
  {
    id: '29674d4352424ecd9ccb15b5d5e7c036',
    name: 'Utiliser les params',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'sleepy-almeida-3n8bg0',
    vimeo: '739556672'
  },
  {
    id: 'ea2788f22f2441f5ba290ba44b9636e5',
    name: 'Les fichiers .hbs',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'hardcore-gwen-dc0khp',
    vimeo: '739556685'
  },
  {
    id: 'e0a66d8a93a44ba3a1ba7e0a0bac7e37',
    name: 'Utiliser la PokeAPI',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    vimeo: '739556698'
  },
  {
    id: '80310f6e35264ac1b0637036c9b01c16',
    name: 'Une fonction pour appeler une liste',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'crazy-tess-wm8e5d',
    vimeo: '739556706'
  },
  {
    id: '9eadb753c0314549b32b069485bba225',
    name: 'Utiliser les boucle avec Handlebars',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'pensive-meadow-j60kft',
    vimeo: '739556717'
  },
  {
    id: 'c718aea84ffd4b428d064dd3f8417bbc',
    name: 'Utiliser une High Order Function (HOF)',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'lucid-ramanujan-p7r27k',
    vimeo: '739556728'
  },
  {
    id: '94d91df718a14610a5284952e2a45480',
    name: "Utiliser les paramètres d'URL",
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'billowing-http-o1b2pp',
    vimeo: '739556742'
  },
  {
    id: '10a3b025531b40dd97447448b1bc7560',
    name: "Afficher une autre page que l'index",
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'sleepy-neumann-k3vm10',
    vimeo: '739556754'
  },
  {
    id: '020a091933684f2ea317e28a4c5c400b',
    name: 'Utiliser les helpers de Handlebars',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'stoic-rain-1rmqsp',
    vimeo: '739556763'
  },
  {
    id: 'b9e60f75371b494aaf2b80e305864142',
    name: 'Ajouter une page 404 Not Found',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'sad-tereshkova-te4x0e',
    vimeo: '739556767'
  },
  {
    id: '9d775b8656a748cd91bac94be37a72b6',
    name: 'Utiliser Body Parser',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'jolly-black-vmjoyd',
    vimeo: '748251263'
  },
  {
    id: '5671a30ea4c04680b27244f167f50d56',
    name: 'Utiliser une requête POST',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '6b4c4b1cdc93413ea1112d15abff030c',
    premium: true,
    codesandbox: 'agitated-water-iyi1zq',
    vimeo: '755136721'
  },
  // // Mongo
  {
    id: '70173d0976a249a0893923a962fcf4d8',
    name: 'Introduction à MongoDB',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: '',
    vimeo: '755136758'
  },
  {
    id: '53d6aad991ef4eea8223ba9a37fbc61f',
    name: 'Initialisation du projet',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'unruffled-dew-8nupfc',
    vimeo: '755136785'
  },
  {
    id: '635869a043b34bc58f8c3313cd30ada3',
    name: 'Lancer un serveur local',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'eager-leaf-nw3oow',
    vimeo: '739556953'
  },
  {
    id: 'bbbf8337a4154e0dabc842930cc400dd',
    name: 'Comprendre le fichier DOTENV',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'green-thunder-xxbbrn',
    vimeo: '739556972'
  },
  {
    id: '212b41068785454e9b5c1d12858a09d4',
    name: 'Utiliser un fichier .env sur Codesandbox',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    vimeo: '755136785'
  },
  {
    id: '1a682130cc434b4ba769f193a3fcac6e',
    name: 'Ajouter la HomePage',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'peaceful-keller-7r7s3k',
    vimeo: '739556984'
  },
  {
    id: 'c8eaa8d7e24f48198a04d81212cbf6da',
    name: 'Les différents types de requêtes',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    vimeo: '739556992'
  },
  {
    id: 'a5a8ca8eb47e4bf3abdaba5ae345fc18',
    name: 'Comprendre le JSON',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'happy-sinoussi-o2k47m',
    vimeo: '739557005'
  },
  {
    id: 'd46e4158d1b14ad28b2f75eafaaf745f',
    name: 'Un service en ligne pour tester ton API',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    vimeo: '755136866'
  },
  {
    id: '8d480b6035bb4c58b8538f70bd7d7b39',
    name: 'Setup du projet',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'quizzical-sunset-nt94pf',
    vimeo: '739557020'
  },
  {
    id: 'c6a6c40b24034a548f7ef270239251ba',
    name: 'Ajouter MongoDB Atlas',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    vimeo: '755136885'
  },
  {
    id: 'f2d3c102d7b242aaa7e34beb182d67b8',
    name: 'Utiliser Mongoose pour structurer la base de données',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'serene-hugle-dxqbip',
    vimeo: '755136913'
  },
  {
    id: '080ac7c26f9349a5ae2fb3e7e73b950c',
    name: 'Comprendre les Schemas',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'pedantic-resonance-ffxqxo',
    vimeo: '739557066'
  },
  {
    id: 'fd2203072c4f44a9a0e93384e727a4a4',
    name: 'Créer des données',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'brave-antonelli-hjbmky',
    vimeo: '739557077'
  },
  {
    id: '67ad532d60b24e0aa535e48bcbf453c7',
    name: 'Utiliser (encore) une High Order Function (HOF)',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'immutable-dream-bjykjq',
    vimeo: '739557093'
  },
  {
    id: '747429942c5a444cba253b4855416c4c',
    name: 'Lire toutes les données',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'vibrant-farrell-7uqgb1',
    vimeo: '739557102'
  },
  {
    id: '4b3dd42a810c41079c735956b68f282c',
    name: 'Lire une donnée spécifique',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'laughing-sound-xk4j4r',
    vimeo: '739557113'
  },
  {
    id: '1d39f0679c1944a88a7dbf7c557de098',
    name: 'Mettre à jour une donnée',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'naughty-ardinghelli-4bil3v',
    vimeo: '739557125'
  },
  {
    id: '0b6bf10d360f49c4a1a8db51f7ad968e',
    name: 'Supprimer une donnée',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
    premium: true,
    codesandbox: 'condescending-paper-unhv6u',
    vimeo: '739557133'
  },
  {
    id: '76eea4f4e17b47ea8b8ea35b6cd65f87',
    name: 'Réaliser un CRUD',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '39feec0033e74f40bf6fb1eb545a69ce',
    premium: true,
    scrimba: 'cQ4pQzTr'
    // codesandbox: 'crud-p1zcvk'
  },
  {
    id: 'ce8d953bf4c645c6aa9710087f1249f1',
    name: 'Réaliser un chat en temps réel',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '39feec0033e74f40bf6fb1eb545a69ce',
    premium: true,
    scrimba: 'co5a247d0a17797ed79bcf092'
    // codesandbox: 'chat-en-temps-reel-qk3llr'
  },
  {
    id: '44d278ec9a1e4f7ea7c73321627fe0ee',
    name: "Détecter l'humeur avec le Natural Language Processing",
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '39feec0033e74f40bf6fb1eb545a69ce',
    premium: true,
    scrimba: 'co2cd493287fb6dd1cbd7d59c'
    // codesandbox: 'detecteur-dhumeur-uzqhu7'
  },
  {
    id: '99707d79345c4d94b8a98011b52a9cdf',
    name: 'Réaliser un gestionnaire de tâches avec authentification',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '39feec0033e74f40bf6fb1eb545a69ce',
    premium: true
    // scrimba: 'cocd04c0f92616df85f2614a7'
    // codesandbox: 'gestionnaire-de-taches-rdncrj'
  },
  {
    id: '8b698bb3036e4da2a70f6fc22f49e9f7',
    name: 'Réaliser un formulaire de contact',
    certification: 'f15c2c6cc5454771840a65469de84b6b',
    chapter: '39feec0033e74f40bf6fb1eb545a69ce',
    premium: true,
    scrimba: 'co749462986ee6e05d2b52fcd'
    // codesandbox: 'formulaire-de-contact-hu2bne'
  },
  // INDIE HACKERS
  {
    id: '45b8ada7c865411781e014d0ec311c31',
    name: 'Introduction et installation',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266281'
  },
  {
    id: '813caca1b60f4f4b937bf843cb7dfeed',
    name: 'Les inférences et le state',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266318'
  },
  {
    id: '47c6b58d174b47ebbd90d838d32a6aba',
    name: 'Les unions avec le state',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266346'
  },
  {
    id: '82e32702e62a4dcbbb51cb34b9152323',
    name: 'Typer des props',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266368'
  },
  {
    id: 'c26fe2f6d305473c9e4d9a251ba40014',
    name: 'Optional properties',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266398'
  },
  {
    id: '82449c70db3d4589ac55ed4fbf6a4399',
    name: 'Unions dans les props',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266429'
  },
  {
    id: 'f02097ae07c341b4859c7d383c9e6a92',
    name: 'Children Props',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266458'
  },
  {
    id: 'b9a4b1f6b1fd44bc9238c143067afffc',
    name: 'Utiliser PropsWithChildren',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266489'
  },
  {
    id: 'e7dbcb4ca4be47e293435467e0e000f5',
    name: 'Type pour un fetch',
    certification: '16aeafbbf7464cbe83aa16986ce94b21',
    chapter: 'dba96816900845d4bd1bfc2c700fbb51',
    premium: true,
    vimeo: '808266522'
  },
  // {
  //   id: 'b526f6ed6dab47db93ffe18170b59f2a',
  //   name: 'Mettre en ligne un projet Node avec Heroku',
  //   certification: 'f15c2c6cc5454771840a65469de84b6b',
  //   chapter: '02f8dbd02b734cf1b2e3571d5cc9b119',
  //   premium: true,
  //   codesandbox: '',
  //   vimeo: '739557153'
  // },
  {
    id: 'last',
    name: 'Merci',
    date: 1631224800000,
    certification: '',
    chapter: '',
    premium: false
  }
]

export default lessons
