import { createContext } from 'react'

import useLessons from '../hooks/useLessons'

const LessonsContext = createContext(null)

export const LessonsProvider = ({ children }) => {
  const { ...props } = useLessons()

  return (
    <LessonsContext.Provider value={{ ...props }}>
      {children}
    </LessonsContext.Provider>
  )
}

export default LessonsContext
