const responsive = [
  {
    id: '949ac38b30c24861b807e7ed33e8496e',
    name: 'Les bases du HTML',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: false,
    level: 1
  },
  {
    id: '5c80db0e4c744434b15fae6d9b2bfacc',
    name: 'Les bases du CSS',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: false,
    level: 1
  },
  {
    id: '55eddb0a637a46238c3de1af581afcee',
    name: 'CSS Niveau 2',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: true,
    level: 2
  },
  {
    id: '44f6d723df7646eab9b165679aeae67b',
    name: 'Web Design Responsive',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: true,
    level: 2
  },
  {
    id: '9b52e15528194f47854757deb85869b7',
    name: 'CSS Flexbox',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: true,
    level: 3
  },
  {
    id: '73410e97d5fe40318f0979b455cb55ae',
    name: 'CSS Grid',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: true,
    level: 3
  },
  {
    id: 'd40cb1bfdc4d4c4098c08659175a56cd',
    name: 'Certification',
    certification: 'd264d4f791ca4303b3b43a871cfe2436',
    pro: true,
    level: 4
  }
]

export default responsive
