const frontend = [
  {
    id: 'f2752e6fb398414d9a7b2a5113afd14c',
    name: 'Rendre un site web interactif',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: false,
    level: 1
  },
  {
    id: '5f5ee00339f3412abf95f8eb891934e2',
    name: 'Les bases de React',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 2
  },
  {
    id: 'b1070008b30e471ebb4277ce2ddbe14a',
    name: 'JavaScript asynchrone et requêtes HTTP',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 2
  },
  // {
  //   id: 'ff1a1ff64afa406a8154430615571432',
  //   name: 'React Hooks',
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   pro: true,
  //   level: 2
  // },
  {
    id: 'a74c6d6e0c63408ca1bcc2090774fb60',
    name: 'CSS-in-JS avec styled-components',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 2
  },
  // {
  //   id: '2a2a6f2f87224a5fac653e67d205b163',
  //   name: 'Ant Design le Framework CSS',
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   pro: false,
  //   level: 2
  // },
  {
    id: 'b3a1d79295f34cbeaebbfc2728e1177f',
    name: 'React Router',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 2
  },
  {
    id: 'ee2266a6543941dbb3c910b046faa1c7',
    name: 'React niveau 2',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 3
  },
  // {
  //   id: 'ada03963ac904d3db565ee9f82c71e81',
  //   name: 'React Suspens',
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   pro: true,
  //   level: 3
  // },
  {
    id: 'df0e88e5bac2452c9cc9c670f0ffcd80',
    name: 'Gestion du State Avancée',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 3
  },
  // {
  //   id: 'd81ab80502f04a18a63c74c0b2627d14',
  //   name: 'React et Redux',
  //   certification: '93921cc46acb48dcb0ad3d5e68164d91',
  //   pro: true,
  //   level: 3
  // },
  {
    id: '0ebd3d7a874d4c18bdb1f27609711930',
    name: 'Certification',
    certification: '93921cc46acb48dcb0ad3d5e68164d91',
    pro: true,
    level: 4
  }
]

export default frontend
