import { createContext } from 'react'

import useNext from '../hooks/useNext'

const NextContext = createContext(null)

export const NextProvider = ({ children }) => {
  const { ...props } = useNext()

  return (
    <NextContext.Provider value={{ ...props }}>{children}</NextContext.Provider>
  )
}

export default NextContext
