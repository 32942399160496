// const API =
//   process.env.NODE_ENV !== 'production'
//     ? `http://localhost:5001/hardcoders-ac92a/europe-west1/api`
//     : 'https://europe-west1-hardcoders-ac92a.cloudfunctions.net/api'
/**
 * A helper function to fetch data from your API.
 * It sets the Firebase auth token on the request.
 */
export async function fetchFromAPI(user, endpointURL, opts) {
  const { method, body } = { method: 'POST', body: null, ...opts }

  // const user = auth.currentUser
  const token = user && (await user.getIdToken())

  const res = await fetch(`${process.env.REACT_APP_API}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })

  return res.json()
}

export function flattenObject(arr) {
  const flatObject = {}
  for (let i = 0; i < arr.length; i++) {
    for (const property in arr[i]) {
      flatObject[`${property}`] = arr[i][property]
    }
  }
  return flatObject
}

export function listFibonacci(n) {
  let fibonacci = [1, 2]
  for (let i = 1; i < n; i++) {
    if (fibonacci[i] > n) {
      break
    }
    fibonacci.push(fibonacci[i] + fibonacci[i - 1])
  }

  return fibonacci
}

// format date and Time calcul

export const formatDate = timestamp => {
  const date = new Date(timestamp)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  return `${date.toLocaleDateString(
    'fr-FR',
    options
  )} - ${date.toLocaleTimeString()}`
}

export const timestampFormated = time => {
  const hours = Math.floor(time / 60 / 60)
  const minutes = Math.floor((time - hours * 60 * 60) / 60)
  const seconds = time - hours * 60 * 60 - minutes * 60

  const hoursText = hours > 0 ? `${hours}h` : ''

  return `${hoursText}${minutes}m${seconds}s`
}
