import { createContext } from 'react'

import useRewardful from '../hooks/useRewardful'

const RewardfulContext = createContext(null)

export const RewardfulProvider = ({ children }) => {
  const { ...props } = useRewardful()

  return (
    <RewardfulContext.Provider value={{ ...props }}>
      {children}
    </RewardfulContext.Provider>
  )
}

export default RewardfulContext
