export const colors = {
  primary: '#45D62F',
  accent: '#E72A8B',
  blue: '#347CE1',
  lightBlue: '#A7CCE9',
  darkBlue: '#031F43',
  yellow: '#F3B63F',
  black: '#000022',
  white: '#FFFFFF'
}
