import { useState, useEffect } from 'react'
import firebase from '../firebase'

import { flattenObject } from '../utils/helpers'
import useLessonsContext from './useLessonsContext'
import useLoadingContext from './useLoadingContext'

import { isBefore } from 'date-fns'

const useAuth = () => {
  const { lessons } = useLessonsContext()
  const { setIsLoading } = useLoadingContext()
  const [authUser, setAuthUser] = useState(null)
  const [authUserLessons, setAuthUserLessons] = useState(null)
  const [proLessons, setProLessons] = useState(null)
  const [lastLesson, setLastLesson] = useState(lessons[0])

  useEffect(() => {
    // let unsubscribeUser
    // let unsubscribeLessons

    const unsubscribe = firebase.auth.onAuthStateChanged(async (user) => {
      setIsLoading(true)
      if (user) {
        // console.log(user?.additionalUserInfo)

        const {
          uid,
          displayName,
          photoURL,
          email,
          metadata: { creationTime },
        } = user
        const authUser = {
          uid,
          displayName,
          photoURL,
          email,
          creationTime,
        }

        await firebase.db
          .collection('users')
          .doc(user.uid)
          .onSnapshot(async (doc) => {
            // console.log('Firestore listener')
            const isPastDue = doc?.data()?.status === 'past_due'
            const isPaused = doc?.data()?.paused
            const isPro =
              (!!doc?.data()?.activePlans?.includes('pro') ||
                !!doc?.data()?.activePlans?.includes('lite') ||
                !!doc?.data()?.activePlans?.includes('abo')) &&
              !isPastDue &&
              !isPaused
            const isLite =
              !!doc?.data()?.activePlans?.includes('lite') &&
              !isPastDue &&
              !isPaused
            const isAbo =
              !!doc?.data()?.activePlans?.includes('abo') &&
              !isPastDue &&
              !isPaused

            // Vérifier si le compte est de moins de 15j
            const endWelcomeDate = doc?.data()?.endWelcomeDate?.toDate()
            const isNew = isBefore(Date.now(), endWelcomeDate)

            if (isPro || isLite || isAbo) {
              await firebase.db
                .collection('pro')
                .onSnapshot(async (querySnapshot) => {
                  // console.log('Pro listener')
                  const lessons = []
                  await querySnapshot.forEach((doc) => {
                    lessons.push(doc.data())
                  })
                  const flattenLessons = flattenObject(lessons)
                  setProLessons({ proLessons: flattenLessons })
                })
            } else {
              setProLessons(null)
            }

            const updatedUser = {
              ...authUser,
              ...doc.data(),
              isPro,
              isLite,
              isAbo,
              isNew,
              isPastDue,
              endWelcomeDate,
            }

            setAuthUser(updatedUser)
          })

        await firebase.lessons(user.uid).onSnapshot(async (querySnapshot) => {
          // console.log('Lessons listener')
          const lessons = []
          await querySnapshot.forEach((doc) => {
            lessons.push(doc.data())
          })

          const flattenLessons = flattenObject(lessons)
          setAuthUserLessons({ lessons: flattenLessons })
          // console.log(flattenLessons)
          setIsLoading(false)
        })
      } else {
        setAuthUser(null)
        setLastLesson(null)
        setAuthUserLessons(null)
        setProLessons(null)
        setIsLoading(false)
      }
    })
    return () => unsubscribe()
  }, [authUser?.isPro, authUser?.isLite, authUser?.isPastDue, setIsLoading])

  // take data of the last lesson user use
  useEffect(() => {
    if (authUserLessons) {
      const arrLessonsUser = Object.keys(authUserLessons?.lessons).map(
        (lesson) => {
          return { timestamp: authUserLessons.lessons[lesson], id: lesson }
        }
      )

      const lastLessonsUser = arrLessonsUser.sort(
        (a, b) => b.timestamp - a.timestamp
      )[0]

      // const dataLastLessonUser = lessons.filter(
      //   lesson => lesson.id === lastLessonsUser?.id
      // )[0]

      const lastLessonIndex = lessons.findIndex(
        (lesson) => lesson.id === lastLessonsUser?.id
      )

      const lastLesson = lessons[lastLessonIndex + 1]

      // on met à jour le state lastLesson que si le user a fait au moins une lesson
      if (Object.keys(authUserLessons?.lessons).length > 0) {
        setLastLesson(lastLesson)
      }
    }
  }, [authUserLessons, lessons])

  // useEffect(() => {
  //   setIsLoading(true)
  //   const unsubscribePro =
  //     authUser?.isPro &&
  //     firebase.db.collection('pro').onSnapshot(async querySnapshot => {
  //       // console.log('Pro listener')
  //       const lessons = []
  //       await querySnapshot.forEach(doc => {
  //         lessons.push(doc.data())
  //       })
  //       const flattenLessons = flattenObject(lessons)
  //       setProLessons({ proLessons: flattenLessons })
  //     })
  //   setIsLoading(false)

  //   return () => {
  //     authUser?.isPro && unsubscribePro()
  //   }
  // }, [authUser?.isPro])

  if (authUser) {
    return { ...authUser, ...authUserLessons, ...proLessons, lastLesson }
  }

  return null
}

export default useAuth
