import { useLocation, Navigate } from 'react-router-dom'
import useFirebaseContext from '../hooks/useFirebaseContext'

const RequireAuth = ({ children }) => {
  const { user } = useFirebaseContext()
  const location = useLocation()

  if (!user) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return children
}

export default RequireAuth
