const firebaseConfig = {
  apiKey: 'AIzaSyDpmBBqDIAeanZDj3qmp8euStUXAqpIFQ4',
  authDomain: 'hardcoders-ac92a.firebaseapp.com',
  projectId: 'hardcoders-ac92a',
  storageBucket: 'hardcoders-ac92a.appspot.com',
  messagingSenderId: '562349766956',
  appId: '1:562349766956:web:f0eee0ebb989c0aac3d489',
  measurementId: 'G-SBB5SQGMH4'
}

export default firebaseConfig
