import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useRewardful = () => {
  const [referral, setReferral] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    // console.log(window?.Rewardful?.referral)
    setReferral(window?.Rewardful?.referral)
  }, [pathname])

  return {
    referral
  }
}

export default useRewardful
