import { createGlobalStyle } from 'styled-components'
import { colors } from './colors'
import { darken, saturate, transparentize } from 'polished'

import useFirebaseContext from '../hooks/useFirebaseContext'

const Style = createGlobalStyle`
::selection {
  background-color: ${colors.primary};
  color: ${colors.black};
}
  html {
   font-size: 16px;
   font-family: 'Josefin Sans', sans-serif;
  }
  body {
   color: ${colors.black};
  }

  img {
    /* border-radius: 16rem 1rem 14rem 1rem / 1rem 14rem 1rem 16rem; */
  }

  p, ul, li, input, textarea, form, label {
    font-size: 1.125rem;
  }

  .hc-link {
    color: ${darken(0.1, colors.blue)};
    text-decoration: none;
    transition: 300ms;

    &:hover {
      color: ${saturate(0.4, colors.blue)};
    }
  }

  #axeptio_main_button {
    bottom: 3rem !important;
  }

  .smaller-on-mobile {
    font-size: 2rem;
  }

  // Helpers

  .capitalize {
    text-transform: capitalize;
  }

  // color


  .color-white {
    color: ${colors.white}
  }
  .color-lightblue {
    color: ${colors.lightBlue};
  }
  .color-blue-darken {
    color: ${darken(0.1, colors.blue)};
  }


  .d-block {
    display: block;
  }
  .d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // line-height
  .lh-1 {
    line-height: 1;
  }

  // border
  .br-default {
    border-radius: 16rem 1rem 14rem 1rem / 1rem 14rem 1rem 16rem
  }

  // shadow
  .shadow-default {
    box-shadow: 0 0.33rem 0.33rem -0.33rem rgb(15, 22, 19, 77%);
  }

  // font-family
  .cursive {
    font-family: "Irish Grover", cursive;
  }
  .monospace {
    font-family: "Fira Mono", monospace;
  }

  // font-size
  .fs-08 {
    font-size: 0.8rem;
  }
  .fs-10 {
    font-size: 1rem;
  }
  .fs-13 {
    font-size: 1.3rem;
  }
  .fs-15 {
    font-size: 1.5rem;
  }
  .fs-17 {
    font-size: 1.7rem;
  }
  .fs-20 {
    font-size: 2rem;
  }
  .fs-22 {
    font-size: 2.2rem;
  }
  .fs-25 {
    font-size: 2.5rem;
  }
  .fs-30 {
    font-size: 3rem;
  }
  .fs-35 {
    font-size: 3.5rem;
  }
  .fs-50 {
    font-size: 5rem;
  }

  // font-weight
  .fw-bold {
    font-weight: bold;
  }
  .fw-700 {
    font-weight: 700;
  }

  // text-align
  .t-align-left {
    text-align: left;
  }
  .t-align-center {
    text-align: center;
  }

  // margin
  .m0 {
    margin: 0;
  }
  .mh-auto {
    margin: 0 auto;
  }
  .m-block-0 {
    margin-block: 0;
  }
  .m-block-start-0 {
    margin-block-start: 0;
  }
  .m-block-end-0 {
    margin-block-end: 0;
  }
  .m5 {
    margin: 5rem;
  }
  .mb1 {
    margin-bottom: 1rem;
  }
  .mb3 {
    margin-bottom: 3rem;
  }
  .mb5 {
    margin-bottom: 5rem;
  }
  .mt1 {
    margin-top: 1rem;
  }
  .mt3 {
    margin-top: 3rem;
  }
  .mt5 {
    margin-top: 5rem;
  }

  // padding
  .ph1 {
    padding: 0 1rem;
  }
  .pv2 {
    padding: 2rem 0;
  }
  .pv7 {
    padding: 7rem 0;
  }
  .pb2 {
    padding-bottom: 2rem;
  }
  .pb3 {
    padding-bottom: 3rem;
  }
  .pb5 {
    padding-bottom: 5rem;
  }
  .pb10 {
    padding-bottom: 5rem;
  }
  .pt3 {
    padding-top: 3rem;
  }
  .pt5 {
    padding-top: 5rem;
  }
  .pt8 {
    padding-top: 8rem;
  }

  // max-width
  .max-w70 {
    max-width: 7rem;
  }
  .max-w250 {
    max-width: 25rem;
  }
  .max-w300 {
    max-width: 30rem;
  }
  .max-w400 {
    max-width: 40rem;
  }
  .max-w550 {
    max-width: 55rem;
  }
  .max-w700 {
    max-width: 70rem;
  }
  .max-w800 {
    max-width: 80rem;
  }
  .max-w1000 {
    max-width: 100rem;
  }
  .full-w {
    max-width: 100%;
  }

  // max-height
  .max-h300 {
    max-height: 30rem;
  }

  // Debug
  .debug {
    border: 5px solid red;
  }


// Modal
.ModalTarifs {
  background: ${colors.white};
  max-width: 40rem;
  width: 100%;
  padding: 5rem 5rem 0;
  /* margin: auto;
  margin */
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  border-radius: 16rem 1rem 14rem 1rem / 1rem 14rem 1rem 16rem;
  border: 2px dashed ${colors.blue};
  @media (max-width: 760px) {
    padding: 2rem 2rem 0;
  }

}
.ModalOverlayTarifs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${transparentize(0.1, colors.black)};
}
`

export const GlobalStyle = () => {
  const { user } = useFirebaseContext()

  return <Style user={user} />
}
